<template>
  <div class="memorylist">
    <div class="left">
      <div class="leftTitle">
          <span>备忘录</span>
          <div @click="paixu()">
            <i
              class="el-icon-caret-top"
              :style="{ color: status ? '#409EFF' : '#909399' }"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :style="{ color: status ? '#909399' : '#409EFF' }"
            ></i>
          </div>
        </div>
      <el-scrollbar>
        
        <ul>
          <li
            v-for="(item, index) in datalist"
            :key="index"
            @click="activeindex(item.id, index)"
            :class="active == item.id ? 'rightline' : ''"
            :style="{ order: checkedList.indexOf(index + 1) != -1 ? -1 : 0 }"
          >
            <p class="title">{{ item.title }}</p>
            <p class="time">{{ item.time }}</p>
            <div v-show="active == item.id">
              <i class="el-icon-delete" @click="del(index, item.id)"></i>
              <i class="el-icon-s-promotion" @click="top(item.id)"></i>
            </div>
          </li>
        </ul>
        <div
          class="notes-add d-flex-center"
          @click="add()"
          title="新增备忘录"
          data-v-394c675d=""
        >
          <i class="el-icon" data-v-394c675d=""
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              data-v-394c675d=""
            >
              <path
                fill="currentColor"
                d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64h352z"
              ></path></svg
          ></i>
        </div>
      </el-scrollbar>
    </div>
    <div class="right" v-if="datalist.length > 0">
      
      <input
        type="text"
        v-model="datalist[datalistindex].title"
        class="title"
      />
      <!-- <el-scrollbar> -->
        <el-input
          type="textarea"
          style="border: none"
          placeholder="请输入内容"
          v-model="datalist[datalistindex].content"
        >
        </el-input>
      <!-- </el-scrollbar> -->
      <div class="timeBox">
        <p class="time2">创建：{{ datalist[datalistindex].time }}</p>
        <p class="url" v-if = "datalist[datalistindex].pageUrl"><a :href = "datalist[datalistindex].pageUrl" target="_black">地址</a></p>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src
export default {
  name: "list",
  data() {
    return {
      datalist: [],
      checkedList: [],
      status: true,
      active: 0,
      datalistindex: 0,
    };
  },
  mounted() {},
  methods: {
    del(e, id) {
      this.datalist.splice(e, 1);
      if (this.checkedList.indexOf(id) != -1) {
        this.checkedList.splice(this.checkedList.indexOf(id), 1);
      }
      if( this.datalist.length >= e){
        this.active = this.datalist[this.datalist.length - 1].id
        this.datalistindex = this.datalist.length - 1
      }else{
        this.datalistindex = e
      }
      
      console.log(this.datalist,this.active)
    },
    top(e) {
      if (this.checkedList.indexOf(e) == -1) {
        this.checkedList.push(e);
      } else {
        this.checkedList.splice(this.checkedList.indexOf(e), 1);
      }
    },
    paixu() {
      if (this.status) {
        this.datalist.sort(function (a, b) {
        //   return a.title - b.title;
          let reg = /^\d+$/;
          if (reg.test(a.title) && reg.test(b.title)) {
            return parseInt(a.title) - parseInt(b.title);
          } else if (!reg.test(a.title) && !reg.test(b.title)) {
            return a.title.localeCompare(b.title);
          } else if (reg.test(a.title) && !reg.test(b.title)) {
            return -1;
          } else if (!reg.test(a.title) && reg.test(b.title)) {
            return 1;
          }
        });
      } else {
        this.datalist.sort(function (a, b) {
        //   return b.title - a.title;
          let reg = /^\d+$/;
          if (reg.test(b.title) && reg.test(a.title)) {
            return parseInt(b.title) - parseInt(a.title);
          } else if (!reg.test(a.title) && !reg.test(b.title)) {
            return b.title.localeCompare(a.title);
          } else if (reg.test(a.title) && !reg.test(b.title)) {
            return -1;
          } else if (!reg.test(a.title) && reg.test(b.title)) {
            return 1;
          }
        });
      }
      this.status = !this.status;
    },
    activeindex(e, index) {
      this.active = e;
      this.datalistindex = index;
    },
    add() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      var time =
        year +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        hours +
        ":" +
        minutes;
      var title = year + seperator1 + month + seperator1 + strDate;
      this.datalist.push({
        id: this.datalist.length + 1,
        title: title,
        time: time,
        content: time,
      });
      
      this.active = this.datalist[this.datalist.length - 1].id
      this.datalistindex = this.datalist.length - 1
    },
  },
  created(){
    if (!localStorage.getItem("memoryList")) localStorage.setItem("memoryList", JSON.stringify(this.datalist));
    this.datalist = JSON.parse(localStorage.getItem("memoryList"))
    if(this.datalist.length > 0) this.active = this.datalist[0].id

    if (localStorage.hasOwnProperty("islogin")){
      if(eval(localStorage.getItem("islogin"))){
        axios.get('https://ipage.cloud/get/memorylist').then(
          res => {
            console.log(res.data)
            if(res.data.status == 0){
              this.datalist = res.data.data;
              localStorage.setItem("memoryList", JSON.stringify(this.datalist));
            }
        })
      }
    }

    
  },
  watch:{
    datalist:{
      handler(newValue, oldValue) {
        console.log("dataList数组变化");
        localStorage.setItem("memoryList", JSON.stringify(this.datalist));
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
input:focus-visible {
  border: none;
  outline: none;
}
.memorylist {
  display: flex;
  height: 100%;
  .el-scrollbar {
    height: 85%;
    .el-scrollbar__wrap {
      overflow-x: auto;
      .el-scrollbar__view {
        display: inline-block;
        width: 100%;
      }
    }
  }
  .left {
    flex: 1 0 20%;
    background-color: #ededed;
    position: relative;
    .leftTitle {
      font-size: 26px;
      margin: 20px 0px;
      padding-left: 20px;
      position: relative;
      display: flex;
      font-weight: 1000;
      .el-icon-caret-top {
        font-size: 16px;
        position: absolute;
        top: 6px;
        right: 20px;
        cursor: pointer;
      }
      .el-icon-caret-bottom {
        position: absolute;
        bottom: 6px;
        font-size: 16px;
        right: 20px;
        cursor: pointer;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 0 0 82%;
        padding: 10px 10px 0px;
        position: relative;
        display: block;
        border-bottom: 1px solid #ddd;
        // margin-bottom: 10px;
        cursor: pointer;
        .title {
          font-size: 14px;
          font-weight: 1000;
          display: flex;
          width: 6.9rem;
          white-space: nowrap;
          overflow: hidden; //文本超出隐藏
          text-overflow: ellipsis; //文本超出省略号替代
          margin: 0;
        }
        .time {
            font-size: 0.7rem;
            display: flex;
            color: #939393;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        div {
          position: absolute;
          top: 6px;
          right: 10px;
          i {
            display: block;
            color: #409eff;
            cursor: pointer;
          }
          i:nth-child(1) {
            margin-bottom: 8px;
          }
        }
      }
      .rightline {
        border-left: 6px solid #409eff;
        // border-right: 3px solid #ededed;
        background-color: #fff;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
      }
    }
    .notes-add[data-v-394c675d] {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      background: #1890ff;
      color: #fff;
      border-radius: 50%;
      transition: 0.2s;
      box-shadow: 0 0 5px 2px #0000001f;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .right {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    padding: 10px;
    position: relative;
    .title {
      border: none;
      color: #222;
      font-weight: 700;
      font-size: 16px;
      padding-left: 14px;
      line-height: 1.8;
      display: flex;
      width: 36rem;
    }
    .textareabox{
      height: 520px;
    }
    .el-textarea{
      height: 90%;
    }
    .el-textarea__inner {
      border: none;
      min-height: 38px;
      height: 100%;
      // overflow: hidden;
      background: linear-gradient(
        180deg,
        transparent,
        transparent 27px,
        #f2f2f2 27px,
        #f2f2f2 27px
      );
      background-size: 100% calc(27px + 1px);
      box-shadow: inset 0 1px 0 0 #f2f2f2;
      line-height: calc(27px + 1px);
      font-size: 14px;
    }
    .timeBox {
      display: flex;
      color: #939393;
      font-size: 12px;
      position: absolute;
      bottom: 10px;
      left: 20px;
      .url{
        padding-left: 5rem;
      }
    }
  }
}
</style>
