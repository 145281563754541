<template>
  <div @contextmenu ="appMeunMain()" class="home" id ="refhome"
  :style = "{backgroundImage:'url('+require('../assets/bg/bg00' + bgImgId + '.jpg')+')'}">
    <!-- <div class="time">{{time}}</div>
    <appView/> -->
    <div id = "index_top">
    <div id = "index_time" class="time" @click="changeHomeType">{{time}}</div>
    <div id = "index_date" class="date">{{date}}</div>
    <div :class="[isMobile ? 'search-mobile' : 'search']">
      <search/>
    </div>
    </div>
    <div id = "center" class = "swiper-container" :style = "{'height': appViewHeight,'width': appViewWidth,'overflow-y': isMobile ? 'auto' : ''}" :class="hometype == 0? '' : 'swiper-container visibility-hidden'">
      <div :class="this.isMobile ? 'swiper-wrapper overflow-hidden' : 'swiper-wrapper overflow-y-auto'" >
          <div class="swiper-slide" v-for="(item, index) in list" :key="index" >
            <appView @appViewInfo = "appViewInfo" :time="time" :isMobile = "isMobile" :memoList = "memoList"  @minicompomentClick = "minicompomentClick" @rightClick="rightClick" :showPart="showPart" @updated="updated" ref='appView' :page="index"/>
          </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
    <div v-show="hometype < 2" class = "bottom">
      <draggable id = "bottomLeft" :delay = 'isMobile ? 200 : 5' style ="max-width: 80%;min-width: 4rem;"  class="wrapper-bottom swiper-no-swiping" :list="bottomList"  @update="datadragEnd" :group="groupA" animation="300" :sort= "true">
        <transition-group class="itemAll">
          <div v-for="(item, index) in bottomList" :key="index" @click="commonUseClick(item[0].url)" class="item" :style="{backgroundColor:item[0].value}">
            <img v-if="item.length === 1&&item[0].type == 0" class="appImg" :src="item[0].value =='' ?  'data:image/png;base64,'+item[0].valueBase64 : require('../assets/icon/' + item[0].value)"    :style="{backgroundColor: item[0].bgc}"  alt="">
            <span class="content" v-if="item.length == 1 && item[0].type == 1">{{ item[0].content }}</span>
            <img v-if="item[0].type == 2" class="appImg" :src="item[0].value "    :style="{backgroundColor: item[0].bgc}"  alt="">
            
          </div>
        </transition-group>
      </draggable>
      
      <draggable id = "bottomRight" :delay = 'isMobile ? 200 : 5' style ="max-width:20%;" class="wrapper-bottom swiper-no-swiping" :list="settingList" :group="groupB" animation="300" :sort= "true">
        <transition-group class="itemAll">
          <div v-for="(item, index) in settingList" :key="index" class="item" @click="settingClick(item[0].url)" :style="{backgroundColor:item[0].value}">
            <img :id = "item[0].id" v-if="item.length === 1&&item[0].type == 0 && item[0].url != 'my'" :src="item[0].value =='' ?  'data:image/png;base64,'+item[0].valueBase64 : require( '../assets/icon/' + item[0].value)"  class="appImg"  :style="{backgroundColor: item[0].bgc}"  alt="">
            <img  :id = "item[0].id" v-if="item.length === 1&&item[0].type == 0 && item[0].url == 'my'" :src="loginimg"  class="appImg"  :style="{backgroundColor: item[0].bgc}"  alt="">
            <span class="content" v-if="item.length == 1 && item[0].type == 1">{{ item[0].content }}</span>
           
          </div>
        </transition-group>
      </draggable>

    </div>
    <div class = "beian">
      <a href="http://beian.miit.gov.cn">渝ICP备20000590号-5</a> 
    </div>
    <div id="menu" >
      <ul class = "meun_ul">
        <li @click="addApp" v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            添加图标
            <i class="menu_icon"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><g fill="none"><path d="M10.5 2.75a.75.75 0 0 0-1.5 0V9H2.75a.75.75 0 0 0 0 1.5H9v6.25a.75.75 0 0 0 1.5 0V10.5h6.25a.75.75 0 0 0 0-1.5H10.5V2.75z" fill="currentColor"></path></g></svg></i>
            </span>
        </li>
        <li  @click="changeBackground" v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            切换壁纸
            <i class="menu_icon"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><g fill="none"><path d="M10.5 2.75a.75.75 0 0 0-1.5 0V9H2.75a.75.75 0 0 0 0 1.5H9v6.25a.75.75 0 0 0 1.5 0V10.5h6.25a.75.75 0 0 0 0-1.5H10.5V2.75z" fill="currentColor"></path></g></svg></i>
          </span>
        </li>
        <li v-if = "rightClickType  == 0" @click="setHomePage" class="menu_li">
          <span class="menu_span">
            设为主页
            <i class="menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>  
            </i>
          </span>
        </li>
        <li @click="backUp"  v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            立即备份
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M598.4 831.872H328.192a256 256 0 0 1-34.496-510.528A352 352 0 1 1 598.4 831.872zm-271.36-64h272.256a288 288 0 1 0-248.512-417.664L335.04 381.44l-34.816 3.584a192 192 0 0 0 26.88 382.848z"></path></svg>
            </i>
          </span>
        </li>
        <li @click="addOnePage"  v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            添加一页
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M598.4 831.872H328.192a256 256 0 0 1-34.496-510.528A352 352 0 1 1 598.4 831.872zm-271.36-64h272.256a288 288 0 1 0-248.512-417.664L335.04 381.44l-34.816 3.584a192 192 0 0 0 26.88 382.848z"></path></svg>
            </i>
          </span>
        </li>
        <li @click="deleteOnePage"  v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            删除当页
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M598.4 831.872H328.192a256 256 0 0 1-34.496-510.528A352 352 0 1 1 598.4 831.872zm-271.36-64h272.256a288 288 0 1 0-248.512-417.664L335.04 381.44l-34.816 3.584a192 192 0 0 0 26.88 382.848z"></path></svg>
            </i>
          </span>
        </li>
        <li @click="loadInBookMarks"  v-if = "rightClickType  == 0" class="menu_li">
          <span class="menu_span">
            导入书签
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M598.4 831.872H328.192a256 256 0 0 1-34.496-510.528A352 352 0 1 1 598.4 831.872zm-271.36-64h272.256a288 288 0 1 0-248.512-417.664L335.04 381.44l-34.816 3.584a192 192 0 0 0 26.88 382.848z"></path></svg>
            </i>
          </span>
        </li>
        <li @click="editApp" v-if = "rightClickType  == 1" class="menu_li">
          <span class="menu_span">
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><g fill="none"><path d="M14.499 3a2.5 2.5 0 0 1 2.5 2.5v3.502a2.87 2.87 0 0 0-1 .229V7h-12v7.499a1.5 1.5 0 0 0 1.5 1.5h3.975c-.016.05-.03.103-.043.155l-.211.845H5.5a2.5 2.5 0 0 1-2.5-2.5v-9A2.5 2.5 0 0 1 5.5 3h8.999zm0 1h-9A1.5 1.5 0 0 0 4 5.5V6h12v-.5a1.5 1.5 0 0 0-1.5-1.5zm-3.52 11.376l4.829-4.83a1.87 1.87 0 1 1 2.644 2.646l-4.829 4.828a2.197 2.197 0 0 1-1.02.578l-1.498.375a.89.89 0 0 1-1.078-1.079l.374-1.498c.097-.386.296-.739.578-1.02z" fill="currentColor"></path></g></svg>
            </i>
            编辑
          </span>
        </li>
        <li v-if = "rightClickType  == 1" class="menu_li">
          <span class="menu_span">
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><g fill="none"><path d="M14.499 3a2.5 2.5 0 0 1 2.5 2.5v3.502a2.87 2.87 0 0 0-1 .229V7h-12v7.499a1.5 1.5 0 0 0 1.5 1.5h3.975c-.016.05-.03.103-.043.155l-.211.845H5.5a2.5 2.5 0 0 1-2.5-2.5v-9A2.5 2.5 0 0 1 5.5 3h8.999zm0 1h-9A1.5 1.5 0 0 0 4 5.5V6h12v-.5a1.5 1.5 0 0 0-1.5-1.5zm-3.52 11.376l4.829-4.83a1.87 1.87 0 1 1 2.644 2.646l-4.829 4.828a2.197 2.197 0 0 1-1.02.578l-1.498.375a.89.89 0 0 1-1.078-1.079l.374-1.498c.097-.386.296-.739.578-1.02z" fill="currentColor"></path></g></svg>
            </i>
            调整大小
          </span>
          <div class="menu_size">
          <span @click="editAppSize(1)"  class="menu_span_size">1×1</span>
          <span @click="editAppSize(2)"  class="menu_span_size">1×2</span>
          <span @click="editAppSize(3)"  class="menu_span_size">2×1</span>
          <span @click="editAppSize(4)"  class="menu_span_size">2×2</span></div>
        </li>
        <li @click = "openGroup" v-if = "rightClickType  == 2" class="menu_li">
          <span class="menu_span">
            <i class="menu_icon">
              <img src = "../assets/openlink.svg"/>
            </i>
            打开工作组
            </span>
        </li>
        <li @click = "dissolveApp" v-if = "rightClickType  == 2" class="menu_li">
          <span class="menu_span">
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28"><g fill="none"><path d="M14 2.25a3.75 3.75 0 0 1 3.745 3.55l.005.2h5.5a.75.75 0 0 1 .102 1.493l-.102.007h-1.059l-1.22 15.053A3.75 3.75 0 0 1 17.233 26h-6.466a3.75 3.75 0 0 1-3.738-3.447L5.808 7.5H4.75a.75.75 0 0 1-.743-.648L4 6.75a.75.75 0 0 1 .648-.743L4.75 6h5.5A3.75 3.75 0 0 1 14 2.25zm6.687 5.25H7.313l1.211 14.932a2.25 2.25 0 0 0 2.243 2.068h6.466a2.25 2.25 0 0 0 2.243-2.068L20.686 7.5zm-8.937 3.75a.75.75 0 0 1 .743.648L12.5 12v8a.75.75 0 0 1-1.493.102L11 20v-8a.75.75 0 0 1 .75-.75zm4.5 0a.75.75 0 0 1 .743.648L17 12v8a.75.75 0 0 1-1.493.102L15.5 20v-8a.75.75 0 0 1 .75-.75zM14 3.75a2.25 2.25 0 0 0-2.245 2.096L11.75 6h4.5l-.005-.154A2.25 2.25 0 0 0 14 3.75z" fill="currentColor"></path></g></svg>
            </i>
            解散文件夹
            </span>
        </li>
        <li @click = "deleteApp" v-if = "rightClickType  == 1 || rightClickType  == 2 || rightClickType  == 3" class="menu_li">
          <span class="menu_span">
            <i class="menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28"><g fill="none"><path d="M14 2.25a3.75 3.75 0 0 1 3.745 3.55l.005.2h5.5a.75.75 0 0 1 .102 1.493l-.102.007h-1.059l-1.22 15.053A3.75 3.75 0 0 1 17.233 26h-6.466a3.75 3.75 0 0 1-3.738-3.447L5.808 7.5H4.75a.75.75 0 0 1-.743-.648L4 6.75a.75.75 0 0 1 .648-.743L4.75 6h5.5A3.75 3.75 0 0 1 14 2.25zm6.687 5.25H7.313l1.211 14.932a2.25 2.25 0 0 0 2.243 2.068h6.466a2.25 2.25 0 0 0 2.243-2.068L20.686 7.5zm-8.937 3.75a.75.75 0 0 1 .743.648L12.5 12v8a.75.75 0 0 1-1.493.102L11 20v-8a.75.75 0 0 1 .75-.75zm4.5 0a.75.75 0 0 1 .743.648L17 12v8a.75.75 0 0 1-1.493.102L15.5 20v-8a.75.75 0 0 1 .75-.75zM14 3.75a2.25 2.25 0 0 0-2.245 2.096L11.75 6h4.5l-.005-.154A2.25 2.25 0 0 0 14 3.75z" fill="currentColor"></path></g></svg>
            </i>
            {{rightClickType  == 2 ? '删除文件夹' : '删除'}}
            </span>
        </li>
      </ul>
    </div> 
    <appDialog :isMobile = "isMobile" ref="appDialog" :activeIndex = "activeIndex" :addOrEditIndex = "addOrEditIndex"  @updated = "updated"
            :dialogFormVisible="dialogFormVisible" @close="closeAppDialog" v-if="dialogFormVisible"></appDialog>
    <appEditDialog ref="appEditDialog" :activeIndex = "activeIndex" :appEditIndex = "appEditIndex" :appEditInfileIndex = "appEditInfileIndex" @updated = "updateEdit"
            :dialogFormVisible="dialogEditVisible" @close="closeAppDialog" v-if="dialogEditVisible"
    ></appEditDialog> 
  <bookMarksDialog :dialogFormVisible = "bookMarksDialogVisible" v-if="bookMarksDialogVisible" @updated = "updated"></bookMarksDialog>
  <div class="dialog-compoment">
  <el-dialog
    :visible.sync="dialogVisible1"
    :close-on-click-modal="false"
    v-if="dialogVisible1"
    @close="close('todoListDialog')"
    top = "6vh">
        <Home></Home>
    </el-dialog>
    <el-dialog
    :visible.sync="dialogVisible2"
    :close-on-click-modal="false"
    v-if="dialogVisible2"
    @close="close('memoDialog')"
    top = "6vh">
        <MemoryList></MemoryList>
    </el-dialog>
    <el-dialog
    :visible.sync="dialogVisible3"
    :close-on-click-modal="false"
    v-if="dialogVisible3"
    @close="close('scheduleDialog')"
    top = "6vh">
        <Rili></Rili>
    </el-dialog>
    <contactDialog ref="contactDialog"  @updated = "updated" :dialogFormVisible="contactDialogVisible" @close="closeAppDialog" v-if="contactDialogVisible"
    ></contactDialog> 
    <login-dialog ref="loginDialog" @loadInfoFromCloud = "loadInfoFromCloud"  @updated = "updated" :dialogFormVisible="loginDialogVisible" @close="closeAppDialog" v-if="loginDialogVisible"
    ></login-dialog>
    <chatgpt-dialog :isMobile = "isMobile" ref="gptDialog"  @updated = "updated"  @close="closeAppDialog" :dialogFormVisible= "dialogVisibleGPT" v-if="dialogVisibleGPT"></chatgpt-dialog>
    <el-dialog
      title="您确定要退出吗？"
      :visible.sync="dialogLogoutVisible"
      :width="isMobile ? '90%' : '30%'"
      class="login_out">
      <span  class="logout-dialog__body">退出登录将会失去数据备份、多端同步等功能, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogLogoutVisible = false">取 消</el-button>
        <el-button type="primary" @click="logout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

import draggable from "vuedraggable"
import appView from '@/components/appView.vue'
import appDialog from "@/components/appDialog.vue";
import appEditDialog from "@/components/appEditDialog.vue";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import {list,bottomList,memoList,settingList} from "../components/list";
import Search from '@/components/search.vue';
import { getLunar } from 'chinese-lunar-calendar';
import $ from 'jquery';
import bookMarksDialog from '@/components/bookMarksDialog.vue';
import Home from '@/components/Home.vue'
import MemoryList from '@/components/MemoryList.vue'
import Rili from '@/components/Rili.vue'
import axios from 'axios';
import ContactDialog from '@/components/contactDialog.vue';
import LoginDialog from '@/components/loginDialog.vue';
import ChatgptDialog from '@/components/chatgptDialog.vue';

export default {
  name: 'HomeView',
  components: {
    appView,
    draggable,
    Search,
    appDialog,
    appEditDialog,
    bookMarksDialog,
    Home,
    MemoryList,
    Rili,
    ContactDialog,
    LoginDialog,
    ChatgptDialog,
  },
  data(){
    return{
      bgImgId: 0,
      bookMarksDialogVisible: false,
      time:'',
      rightClickType: 0,
      date: '4月2日 星期日 闰二月十二',
      xingqi: [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六",],
      showPart:false,
      bottomList:[],
      settingList: [],
      isMobile: '',
      dialogFormVisible: false,
      dialogEditVisible: false,
      memoList: [],
      list:[],
      dialogVisible1:false,
      dialogVisibleGPT:false,
      dialogVisible2:false,
      fileDialogVisible: false,
      loginDialogVisible: false,
      dialogVisible3:false,
      contactDialogVisible: false,
      activeIndex: 0,
      dialogLogoutVisible: false,
      addOrEditIndex: 0,
      appViewWidth: '90%',
      appViewHeight: '62%',
      appEditIndex: 0,
      islogin: false,
      appEditInfileIndex: 0,
      afterLoginUpload: false,
      hometype: 0,
      groupA:{
        name:'site',
        pull:true, //可以拖出
        put:true//可以拖入
      },
      groupB:{
        name:'site',
        pull:false, //可以拖出
        put:false//可以拖入
      },
      mySwiper:{},
      loginimg: require( '../assets/icon/no-login.svg'),
    }
  },
  watch:{
    // list(){
    //   console.log(123);
    //   // this.$nextTick(() => {
    //   //     //可以查看真实DOM的src地址
    //   //     // console.log(document.getElementById("app").innerHTML);
    //   //     this.mySwiper.update();
    //   //     console.log(1112);
    //   //   });
    // }
    list: {
      handler(newValue, oldValue) {
        console.log("list数组变化");
      },
      deep: true
    },
    bottomList: {
      handler(newValue,oldValue){
        console.log('底部变化');
        localStorage.setItem("bottomList", JSON.stringify(this.bottomList));
      },
      deep: true
    }
  },
  created(){
    this.updateLoginImg()
    if(this.getDate().minutes < 10){
      this.time = this.getDate().hour+':0'+this.getDate().minutes
    }else{
      this.time = this.getDate().hour+':'+this.getDate().minutes
      
    }
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    this.adaptAppView()
    if (!localStorage.getItem("bottomList")) localStorage.setItem("bottomList", JSON.stringify(bottomList));
    this.bottomList = JSON.parse(localStorage.getItem("bottomList"))
    if (!localStorage.getItem("list")) localStorage.setItem("list", JSON.stringify(list));
    this.list = JSON.parse(localStorage.getItem("list"))
    if (localStorage.getItem("hometype")) this.hometype = localStorage.getItem("hometype")
    
    if (!localStorage.getItem("bgImgId")) localStorage.setItem("bgImgId", this.bgImgId);
    this.bgImgId = localStorage.getItem("bgImgId")

    if(this.islogin){
      this.loadInfoFromCloud()
    }
    //this.bottomList = bottomList
    //localStorage.setItem("bottomList", JSON.stringify(this.bottomList));
    //this.list = list
    //localStorage.setItem("list", JSON.stringify(this.list));
    console.log(list)
    this.settingList = settingList
    this.memoList = memoList
    this.date =  this.getDate().month + '月' + this.getDate().dates + '日 ' + this.xingqi[this.getDate().day] +  ' ' + 
    getLunar(this.getDate().year, this.getDate().month, this.getDate().dates).dateStr
    setInterval(() => {
      if(this.getDate().minutes < 10){
        this.time = this.getDate().hour+':0'+this.getDate().minutes
      }else{
        this.time = this.getDate().hour+':'+this.getDate().minutes
        
      }
    }, 1000);

    //解决this指向问题，在window.addEventListener中this是指向window的。
    //需要将vue实例赋值给_this,这样在window.addEventListener中通过_this可以为vue实例上data中的变量赋值
    let _this=this;
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent",function(e){
      //e.key : 是值发生变化的key
      //e.newValue : 是可以对应的新值
      if(e.key==="list" || e.key==="bgImgId" || e.key==="bottomList"){
        console.log(e.newValue);
        _this.backUpOverall(); 

      }
    })

  },
  beforeDestroy() {
      window.removeEventListener('wheel', this.handleMouseWheel, {
        passive: false,
      })
  },
 mounted(){

  window.addEventListener('wheel', this.handleMouseWheel, {
      passive: false,
    })
  const that = this
  setTimeout(() =>{
    this.adaptAppView()
  },500)
  window.onresize = () => {
          return (() => {
              console.log("屏幕分辨率变化")
              that.adaptAppView()
          })()
  }
  this.mySwiper = new Swiper ('.swiper-container', {
      loop: false, // 循环模式选项
      noSwiping: true,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        observerUpdate: function(){
          alert('监测到DOM变化，更新Swiper')
          console.log('hhh');
        },
        slideChangeTransitionEnd: function(){
          console.log("切换到",this.activeIndex)
          that.activeIndex = this.activeIndex;//切换结束时，告诉我现在是第几个slide
        },
        
        slideChangeTransitionStart: function(){
          //that.$refs.appView1?.reviewList()
          //that.$refs.appView2?.reviewList()
          // that.$refs.appView2?.reviewList()
          // that.$refs.appView3?.reviewList(list,3)
        }, 
        touchMove: function(swiper,event){
          // //你的事件
          // console.log(123);
          if (!localStorage.getItem("list")) localStorage.setItem("list", JSON.stringify(list));
          this.list = JSON.parse(localStorage.getItem("list"))
          // console.log(this.list);
        },
      },
    })
  },
  methods:{
    adaptAppView(){
      var fS = 16
      try {
        this.appViewHeight = window.innerHeight - document.querySelector('.time').scrollHeight -document.querySelector('.date').scrollHeight-document.querySelector('.search').scrollHeight - document.querySelector('.bottom').scrollHeight - fS + 'px'
        
        if(eval(this.appViewHeight.replace('px',''))  < 22.5 * fS){
          fS = eval(this.appViewHeight.replace('px','')) / 22.5
          fS = fS.toFixed(1)
        }else{
          fS = 16
        }
        if(fS < 14){
          fS = 14
        }
        document.documentElement.style.fontSize =  fS + 'px'
        console.log('font-size调整', fS)
        this.appViewHeight = window.innerHeight - document.querySelector('.time').scrollHeight -document.querySelector('.date').scrollHeight-document.querySelector('.search').scrollHeight - document.querySelector('.bottom').scrollHeight - fS + 'px'
        
      } catch (error) {
        console.log('获取dom失败')
      }

      if(window.innerWidth*0.9/26 < fS )  {
        var fontSizeChange = window.innerWidth*0.9/26
        fontSizeChange = fontSizeChange.toFixed(1)
        fS = fontSizeChange
        document.documentElement.style.fontSize =  fontSizeChange + 'px'
        this.appViewWidth =  parseFloat(fontSizeChange) * 26 + 'px'
      }else{
        this.appViewWidth =  (Math.floor((window.innerWidth*0.9 +1.5*fS ) / 5.5/ fS ) * 5.5* fS - 1.5 * fS) + 'px'
        console.log(this.appViewWidth)
      }
      
      
    },
    loadInfoFromCloud(){
      if(this.afterLoginUpload){
        this.islogin = true
        this.backUp()
        this.afterLoginUpload = false
      }else{
          
        axios.get("https://ipage.cloud/ipage/getupload").then(res =>{
          console.log('ipage云端信息获取', res.data)
          if(res.data.status == 0){
            this.bottomList = JSON.parse(res.data.data.bottomList)
            this.bgImgId = res.data.data.bgImgId
            this.list = JSON.parse(res.data.data.list)
            localStorage.setItem("bottomList", JSON.stringify(this.bottomList));
            localStorage.setItem("list", JSON.stringify(this.list));
            localStorage.setItem("bgImgId", this.bgImgId);

          }
        })
      }
    },
    openGroup(){
      var fileList = this.list[this.activeIndex][this.appEditIndex]
      console.log(fileList)
      for(var i = 0; i < fileList.length; i ++){
        console.log(fileList[i].url)
        window.open(fileList[i].url, "_blank")
      }
    },
    updateLoginImg(){
      console.log(this.islogin,localStorage.getItem("islogin") )
      if(localStorage.hasOwnProperty("islogin")) this.islogin = eval(localStorage.getItem("islogin"))
        if(this.islogin){
          this.loginimg = require( '../assets/icon/login.svg')
        }else{
          this.loginimg = require( '../assets/icon/no-login.svg')
        }
    },
    changeHomeType(){
      this.hometype = (this.hometype + 1) %  3
      console.log("hometype",this.hometype)
      localStorage.setItem("hometype", this.hometype);
      this.$message({
          message: '点击时间可以快速切换3种不同模式',
          type: 'success'
        });
    },
    close(dialogId){
        console.log(dialogId,' 组件关闭')
        console.log( this.$refs.appView[this.activeIndex])
        console.log( this.$refs.appView[this.activeIndex].$refs.memoDialog)
        if (dialogId == 'memoDialog') {
          if(this.islogin){
            axios.post("https://ipage.cloud/upload/memorylist",{"memorylist": localStorage.getItem('memoryList')}).then(
			        res => {
            })
          }
          this.$refs.appView[this.activeIndex].$refs.memoDialog[0].updated()
        }else if (dialogId == 'scheduleDialog') {
          this.$refs.appView[this.activeIndex].$refs.scheduleDialog[0].updated()
        }else if (dialogId == 'todoListDialog') {
          this.$refs.appView[this.activeIndex].$refs.todoListDialog[0].updated()
        }
        
    },
    onSwiper(){},
    loadInBookMarks(){
      this.bookMarksDialogVisible =true
    },
    onSlideChangeEnd(swpier){
      console.log(swpier);
    },
    changeBackground(){
      console.log('切换壁纸')
      this.bgImgId = (this.bgImgId + 1 )% 37;
      localStorage.setItem("bgImgId", this.bgImgId);
    },
    minicompomentClick(componentUrl){
      console.log('打开小组件',componentUrl)
      if(componentUrl == 'calendar'){
            this.dialogVisible1=false
            this.dialogVisible2=false
            this.dialogVisible3=true

      }else if(componentUrl == 'memo'){
            this.dialogVisible1=false
            this.dialogVisible2=true
            this.dialogVisible3=false
        
      }else if(componentUrl == 'schedule'){
            this.dialogVisible1=true
            this.dialogVisible2=false
            this.dialogVisible3=false
        
      }else if(componentUrl == 'chatgpt'){

            window.open('https://chat18.aichatos.xyz/')
            // this.dialogVisible1=false
            // this.dialogVisible2=false
            // this.dialogVisible3=false
            // this.dialogVisibleGPT = true
      }else if(componentUrl == 'add'){
          this.dialogFormVisible = true

            this.dialogVisible1=false
            this.dialogVisible2=false
            this.dialogVisible3=false
            this.dialogVisibleGPT = false
      }
    },
    addOnePage(){
      this.$set(this.list,this.list.length, [])
      localStorage.setItem("list", JSON.stringify(this.list))

      this.$nextTick(() => {
          //可以查看真实DOM的src地址
          // console.log(document.getElementById("app").innerHTML);
          this.mySwiper.update();
          this.activeIndex = this.list.length - 1
          this.mySwiper.slideTo(this.list.length - 1, 1000, true);
        });
      this.$message({
          message: '新增页面成功',
          type: 'success'
        });
    },
    deleteOnePage(){
      if(this.list.length == 1){
        this.$message({
          message: '主页无法删除',
          type: 'error'
        });
        return
      }
      this.list.splice(this.mySwiper.activeIndex ,1)
      if (this.list.length == this.activeIndex){
        this.activeIndex = this.activeIndex - 1
      }
      localStorage.setItem("list", JSON.stringify(this.list))
      this.$nextTick(() => {
           //可以查看真实DOM的src地址
           // console.log(document.getElementById("app").innerHTML);
           this.mySwiper.update();
           for(var i = this.activeIndex;i < this.list.length;i ++){
              this.$refs.appView[i].updatePageIcon()
           }
         });
      this.$message({
          message: '此页删除成功',
          type: 'success'
        });
    },
    backUp(){      
      if(!this.islogin){
        this.$message.error( '备份失败，请登录后重试');
        this.loginDialogVisible = true
        this.afterLoginUpload = true
      }else{     
        var ipageInfo = {
          "list": localStorage.getItem("list"),
          "bgImgId": localStorage.getItem("bgImgId"),
          "bottomList": localStorage.getItem("bottomList")
        }
        axios.post("https://ipage.cloud/ipage/upload", ipageInfo).then(res => {
          console.log(res)
          if(res.data.status == -1){
            this.$message({
              message: '备份失败，请登录后重试',
              type: 'success'
            });
          }else{
            this.$message({
              message: '备份成功',
              type: 'success'
            });
          }
          
        })
      }
    },
    backUpOverall(){   
      if(this.islogin){
        var ipageInfo = {
          "list": localStorage.getItem("list"),
          "bgImgId": localStorage.getItem("bgImgId"),
          "bottomList": localStorage.getItem("bottomList")
        }
        axios.post("https://ipage.cloud/ipage/upload", ipageInfo).then(res => {
          console.log(res)
        })

      }
    },
    closeAppDialog(){
      this.dialogFormVisible =false
      this.updated
    },
    datadragEnd(evt) {//图标志位置交换
        localStorage.setItem("list", JSON.stringify(this.list))
    },
    rightClick(value,index,inFileIndex){
      this.rightClickType = 1;//操作图标
      this.list = JSON.parse(localStorage.getItem("list"))
      if(this.list[value][index][0].type == 3 || inFileIndex == -1){
        this.rightClickType = 2;//操作文件夹
      }
      if(this.list[value][index][0].type == 3){
        this.rightClickType = 3;//操作小组件
      }
      this.showRightClickMenu();
      this.appEditIndex = index
      this.appEditInfileIndex = inFileIndex
      console.log('编辑app',value,index,inFileIndex);
    },
    commonUseClick(url){
        window.open(url)
    },
    settingClick(url){
      if(url == "history"){
        this.contactDialogVisible = true
      }else if(url == "bookmarks"){
        window.open("https://ipage.cloud/info")
      }else if(url == "my"){
        if(this.islogin){
          this.dialogLogoutVisible = true
        }else{
          this.loginDialogVisible = true
        }
      }
    
    },
    logout(){
      axios.get("https://ipage.cloud/user_logout" ).then(res => {
        this.$message({
                message: '退出成功',
                type: 'success'
              });
        this.dialogLogoutVisible =false
        this.islogin = false
        localStorage.setItem("islogin",false)
        this.updateLoginImg()
      })
    },
    appMeunMain(){
      this.rightClickType = 0;
      this.showRightClickMenu();
    },
    addApp(){
      this.dialogFormVisible = true;
    },
    editApp(){
      this.dialogEditVisible = true;
    },
    editAppSize(size_index){
      console.log(this.list[this.activeIndex][this.appEditIndex])
      if(size_index == 1){
        this.list[this.activeIndex][this.appEditIndex][0].size = '1-1'
      }else if(size_index == 2){
        this.list[this.activeIndex][this.appEditIndex][0].size = '1-2'
      }else if(size_index == 3){
        this.list[this.activeIndex][this.appEditIndex][0].size = '2-1'
      }else if(size_index == 4){
        this.list[this.activeIndex][this.appEditIndex][0].size = '2-2'
      }
      localStorage.setItem("list", JSON.stringify(this.list))
      this.$refs.appView[this.activeIndex].close()
      this.$message({
          message: '图标大小修改成功',
          type: 'success'
        });
    },
    dissolveApp(){
        var fileList = this.list[this.activeIndex][this.appEditIndex]
        this.list[this.activeIndex].splice(this.appEditIndex,1)
        for(var i = 0;i < fileList.length; i++){
          this.list[this.activeIndex].push([fileList[i]])
        }
        localStorage.setItem("list", JSON.stringify(this.list))
        this.$refs.appView[this.activeIndex].close()
        this.$message({
            message: '文件夹解散成功',
            type: 'success'
          });
    },
    deleteApp(){
      if(this.appEditInfileIndex == -1){
        this.list[this.activeIndex].splice(this.appEditIndex,1)
      }else if(this.appEditInfileIndex == 0 &&  this.list[this.activeIndex][this.appEditIndex].length == 1){
        this.list[this.activeIndex].splice(this.appEditIndex,1)
      }else{
        this.list[this.activeIndex][this.appEditIndex].splice(this.appEditInfileIndex,1)
      }
      localStorage.setItem("list", JSON.stringify(this.list))
      this.$refs.appView[this.activeIndex].close()
      this.$message({
          message: '删除成功',
          type: 'success'
        });
    },
    setHomePage(){
      //document.setHomePage(window.location.href)
      window.open("https://ipage.cloud/info")
    },
    showRightClickMenu(){
      var e = window.event;
      e.preventDefault(); //阻止系统右键菜单 IE8-不支持
      // 显示自定义的菜单调整位置
      console.log(e.path,'path')
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取垂直滚动条位置
      let scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft; // 获取水平滚动条位置
      
      var menu = document.querySelector("#menu")
      menu.style.display = 'none';
      menu.style.left =   '0px';
      menu.style.top =   '0px';
      setTimeout(() => {
      menu.style.display = 'block';
        if (e.clientX + menu.offsetWidth > document.documentElement.clientWidth) {
          menu.style.left = e.clientX + scrollLeft - menu.offsetWidth + 'px';
        } else {
          menu.style.left = e.clientX + scrollLeft + 'px';
        }
        if (e.clientY + menu.offsetHeight > document.documentElement.clientHeight) {
          menu.style.top = e.clientY + scrollTop - menu.offsetHeight + 'px';
        } else {
          menu.style.top = e.clientY + scrollTop + 'px';
        }
        },100)
      console.log('点击', e.clientX, e.clientY)
      console.log('菜单大小', menu.offsetWidth, menu.offsetHeight)
      console.log('高度', document.documentElement.clientWidth, document.documentElement.clientHeight)
    },
    getDate(){
      var date = new Date();
        var year = date.getFullYear();    //  返回的是年份
        var month = date.getMonth() + 1;  //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate();       //  返回的是几号
        var day = date.getDay();          //  周一返回的是1，周六是6，但是周日是0
        var hour = date.getHours();
        var minutes= date.getMinutes()
        return {
          year ,month ,dates ,day ,hour,minutes
        }
    },
    appViewInfo(fileDialogVisible){
      this.fileDialogVisible = fileDialogVisible
    },
    updated(type){
      console.log('updated', type)
      this.updateLoginImg()
      if(type != "addAppContinue"){
        this.dialogFormVisible = false;
      }
      this.dialogEditVisible = false;
      this.bookMarksDialogVisible = false;
      this.contactDialogVisible = false;
      this.loginDialogVisible = false;
      this.dialogVisibleGPT = false;
      this.list = JSON.parse(localStorage.getItem("list"))
      this.$refs.appView[this.activeIndex].close()
      this.$nextTick(() => {
        this.mySwiper.update();
        if(type == 'add'){
          console.log(this.list.length)
          this.mySwiper.slideTo(this.list.length - 1, 1000, true);
        }
      })
      
    },
    updateEdit(appInfo){
      console.log("父组件接收   app信息更新", appInfo)
      console.log('更新前',this.list)
      this.dialogFormVisible = false;
      this.dialogEditVisible = false;
      this.list[this.activeIndex][this.appEditIndex][this.appEditInfileIndex] = appInfo
      
      console.log('更新后',this.list)
      localStorage.setItem("list", JSON.stringify(this.list))
      this.$refs.appView[this.activeIndex].close()
    },
    handleMouseWheel(e) {
      if (!window.scrollY && !this.dialogEditVisible && !this.dialogFormVisible  && !this.dialogVisible1 && !this.dialogVisible2 && !this.dialogVisible3 && this.hometype == 0 && !this.fileDialogVisible && !this.dialogVisibleGPT) {
        // 禁止页面滚动
        // e.preventDefault()
        
        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.mySwiper.slidePrev();
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            this.mySwiper.slideNext();
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            this.mySwiper.slideNext();
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.mySwiper.slidePrev();
          }
        }
      }
    }


  },
}
document.onclick = function(){
    //console.log('点击空白处')
    menu.style.display = 'none';
  }

</script>
<style lang="less">
.swiper-container {
    width: 90%;
    height: 62%;
    overflow: hidden;
    .swiper-wrapper{
      top: 3%;
    }
}
.time{
  text-align: center;
  font-size: 5rem;
  color: #fff;
  width: fit-content;
  align-items: center;
  justify-content: center;
  display: inline-block;
}
.date{
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  padding-bottom: 0.5rem;
}
.search{
  margin: auto;
  width: 60%;
  height: 3rem;
}
.search-mobile{
  height: 6.5%;
  margin: auto;
  width: 90%;
}
.home{
  width: 100%;
  height: 100%;
  background-image: url('../assets/bg/bg000.jpg');
  background-size:100% 100%;
}
.wrapper-bottom{
  width: fit-content;
  /* position: fixed; */
  z-index: 2;
  bottom: 0px;
  margin: auto;
  display: inline-block;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 1rem;
  height: 4rem;
  overflow: hidden;
  .item {
    margin: 0.5rem;
    width: 3rem;
    height: 3rem;
    background-color: #4e5250;
    color: #ffffff;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    .content{
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 12px;
      font-weight:bold;
      transform: translate(-50%, -50%);
    }
      .appImg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  .itemAll{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0.1rem 0.2rem;
    }
}
.swiper-pagination{
  bottom: 0px!important;
  width: 100%;
}

.verhicleLine{
  float:left;
  margin-top: 30px;
  width: 1px;
  height: 400px;
  background: darkgray;
  margin-left: 800px;
}


#menu{
  position: absolute;
  width: 8rem;
  padding-bottom: 0.5rem;
  display: none;
  border-radius: 1rem;
  background-color: #0b0b0bcc;
  z-index: 10;

}

.meun_ul{
  list-style: none;
  color: white;
  padding-inline-start: 0px;
  font-size: 0.7rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  
  animation:  scale .2s both;
}
.menu_li{
  padding-top: 0.5rem;
  .menu_size{
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .menu_span_size{
    background: #ffffff4d;
    border-radius: 0.5rem;
    padding: 0.1rem;
    margin-left: 0.2rem;
    cursor: pointer;
  }
}
.menu_span{
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-left: 4px;
    cursor: pointer;
}
.menu_icon{
  width: 1rem;
}
.copyright_quanzhan{
  padding: 1rem;
}

.dialog-compoment{
   
    .button{
        display: flex;
        li{
            margin:0 20px;
        }
    }
    .el-dialog__body{
        padding: 0;
        height: 100%;
    }
    .el-dialog__header{
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
        background: transparent;
        padding: 0;
    }
    .el-dialog__headerbtn{
        top: 10px;
        right: 10px;
    }
    .el-dialog{
        max-width: 800px;
        max-height: 600px;
        height: 90%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 7vh;
        width: 90%;
    }
}
.visibility-hidden{
  visibility: hidden;
}
.bottom {
    //height: 10%;
    overflow: hidden;
    max-height: 4.2rem;
    border-radius: 1rem;
    position: absolute;
    left: 0;
    bottom: 0.5rem;
    //bottom: 2%;
    right: 0;
}
.beian{
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0);
  }
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 0.5rem;
}
.logout-dialog__body{
          font-size: 1rem;
          padding: 1rem;
  
}
.login_out{
  height: fit-content;
  .el-dialog__body{
    margin-top: 3rem;
  }
  .el-dialog__header{
    padding-top: 0.5rem;
  }
}
</style>
