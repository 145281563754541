<template>
    <div class="todoList">
        <div style="display: flex;justify-content: center;align-items: center;padding-top: 1rem;">
            <div class="todoListIcon">
                <img class = "todo-img" src="../assets/list_2.svg"/>
            </div>
            <div class="todo-num">{{dataList.length}}</div>
        </div>
        <div class="todo-title" v-if="dataList.length == 0">提醒事项</div>
        <div class="todo-content_none" v-if="dataList.length == 0">没有提醒事项</div>
        <div v-if="dataList.length > 0">
            <div class="todo-content"  :style="{'color': colorList[item.status]}"  v-for="(item, index) in dataList.slice(0,4)" :key="index" >{{item.title}}</div>
        </div>
    </div>
</template>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
<script>

export default {
  name: "todoList",
  data() {
    return {
        dataList: [],
        todoList: {},
        datalistPre: [],
        checkedList: [],
        colorList: [ '#DCDFE6','#E03131','#FFB000','#4772FA','#939393']
    }
  },
  methods:{
    updated() {
      if (localStorage.getItem("todoList")){
        this.todoList = JSON.parse(localStorage.getItem("todoList"))
        this.dataList = this.todoList['datalistDo']
        
      } 
      
      console.log('memo 更新', this.dataList)
    },
  },
  created(){
    this.updated()
  },
}
</script>

<style>
.todoList{
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    overflow: hidden;
    border-radius: 1rem;
    
}
.todoListIcon {
    width: 3.5rem;
    border-radius: 2rem;
    height: 2rem;
    background-color: rgba(46,123,253,1);
    margin-left: 1rem;
    align-items: center;
    justify-content: center;
    /* display: inline; */
}
.todo-img {
    width: 1.5rem;
    fill: currentColor;
    color: white;
    /* display: inline; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-top: 0.25rem;
}
.todo-num {
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
    justify-content: right;
    display: flex;
    padding-right: 1rem;
}
.todo-title{
    display: flex;
    padding-left: 1rem;
    color: rgba(46,123,253,1);
    font-weight: bold;
    padding-top: 2rem;

}
.todo-content {
    margin-left:1rem;
    font-size: 0.9rem;
    color: gray;
    border-bottom: 1px dashed darkgray;
    overflow: hidden;
    padding-top: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 8rem;
    text-align: start;
}
.todo-content_none {
    margin: 0 1rem;
    font-size: 0.9rem;
    color: gray;
    overflow: hidden;
}
</style>