<template>
  <div class="chatgpt">
      <img src = "../assets/icon/gpt.svg">
  </div>
</template>

<script>
export default {
name: "todoList",
    data() {
        return {
        }
    },
    methods:{
        created() {
            console.log('chatgpt-created')
        },  
    }
}
</script>

<style lang = "less">
.chatgpt{
    img{
        width: 100%;
        background: #0ea27e;
        border-radius: 1rem;
    }
}
</style>