<template>
  <div class = "memo">
      <div class="memo-head">备忘录</div>
      <div class = "memo-content"><div  class="meno-content-div" v-if="memoList.length > 0">{{memoList[0].title}}</div></div>
      <div class = "memo-content"><div  class="meno-content-div"  v-if="memoList.length > 1">{{memoList[1].title}}</div></div>
      <div class = "memo-content"><div class="meno-content-div" v-if="memoList.length > 2">{{memoList[2].title}}</div></div>
  </div>
</template>

<script>

export default {
  name: "memo",
  components: { },
  props:[],
  data() {
    return {
      memoList: [],
    }
  },
  created(){
    if (localStorage.getItem("memoryList")) this.memoList = JSON.parse(localStorage.getItem("memoryList"))
  },
  methods:{
    updated() {
      console.log('memo 更新')
      this.memoList = JSON.parse(localStorage.getItem("memoryList"))
    },
  }
}
</script>

<style lang="less">
.memo{
    
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1rem;
}
.memo-head {
    background-image: linear-gradient(0deg,#ffc927,#ffa403);
    width: 100%;
    height: 25%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.2rem;
}
.memo-ul{
    height: 75%;
    list-style: none;
}
.memo-content {
    height: 25%;
    background-color: white;
    border-bottom: 2px solid #f2f2f2;
    color: #000;
    align-items: center;
    display: flex;
    padding: 0 0.5rem;
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    .meno-content-div{
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: start;
    }
}

</style>