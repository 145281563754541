import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueContextMenu from 'vue-contextmenu'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'

import metaInfo from 'vue-meta-info'
Vue.use(metaInfo)
Vue.use(ElementUI);
Vue.use(VueContextMenu)
Vue.config.productionTip = false

import tool from "./utills/tool";
Vue.use(tool);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  console.log = function () {}
  console.error = function(){}
  console.dir = function(){}
}