<template>
  <div class="rili">
    <div class="left">
        <ul class="leftTitle">
            <li :class="leftTitleIndex?'on':''" @click="leftTitleIndex=true">日历</li>
        </ul>
        <el-calendar v-model="nowDate"></el-calendar>
    </div>
    <div class="right">
        <div class="rightTop">
            <div class="msg1">
                <p class="p1">
                    <span>{{getDayStr(nowDate)}}</span>
                    <span>{{xingqi[nowDate.getDay()]}}</span>
                </p>
                <p class="p2">{{nowDate.getDate()}}</p>
                <p class="p3">{{lunar }}</p>
            </div>
        </div>
        <div class="rightBottom">
            <div class="top">
                    <ul>
                        <li v-for="(item,index) in datalistDo" :key="index">
                            <div class="list1">
                            <el-checkbox v-model="item.checked" :label="item.id" @change="checkBoxChange(index,'do')" :class="['checkbox' + item.status]"   >{{item.title}}</el-checkbox>
                            </div>
                            <div class="list2">
                                <el-popover
                        placement="bottom"
                        width="260"
                        trigger="click">
                            <div class="selectBox">
                            <span class="tip">优先级</span>
                            <span class="select select1" @click="changeColor(index,1)">
                                <i class="el-icon-check" v-show="item.status==1"></i>
                            </span>
                            <span class="select select2" @click="changeColor(index,2)">
                                <i class="el-icon-check" v-show="item.status==2"></i>
                            </span>
                            <span class="select select3" @click="changeColor(index,3)">
                                <i class="el-icon-check" v-show="item.status==3"></i>
                            </span>
                            <span class="select select4" @click="changeColor(index,4)">
                                <i class="el-icon-check" v-show="item.status==4"></i>
                            </span>
                            </div>
                            <i class="el-icon-more button" slot="reference"></i>
                        </el-popover>
                            <i class="el-icon-delete" @click="del(index,'do')"></i>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li v-for="(item,index) in datalistNotdo" :key="index">
                            <div class="list1">
                            <el-checkbox v-model="item.checked" :label="item.id" @change="checkBoxChange(index,'notdo')" class="check">{{item.title}}</el-checkbox>
                            </div>
                            <div class="list2">
                            <i class="el-icon-more" style = "visibility:hidden;"></i>
                            <i class="el-icon-delete" @click="del(index,'notdo')"></i>
                            </div>
                        </li>
                    </ul>
            </div>
            <div class="bottom">
                <i class="el-icon-plus" @click="add()"></i>
                <input type="text" placeholder="添加任务" v-model="value" @keyup.enter="add()">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getLunar } from 'chinese-lunar-calendar';

export default {
    name:"rile",
    data() {
        return {
            value:"",
            nowDate: new Date(),
            leftTitleIndex:true,
            DayData:{},
            year:'',
            datalistDo:[ ],
            datalistNotdo:[ ],
            riliSchedule: {},
            xingqi: [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六",],
            nowFormatDate: '',
            lunar: ''
        }
    },
    mounted() {},
    created(){
        if (localStorage.getItem("riliSchedule")) this.riliSchedule = JSON.parse(localStorage.getItem("riliSchedule"))
        this.nowFormatDate = this.getDayStr(this.nowDate)
        console.log('创建时', this.nowFormatDate)
        if(this.riliSchedule[this.nowFormatDate]){
            if(this.riliSchedule[this.nowFormatDate]['datalistDo']){
                this.datalistDo = this.riliSchedule[this.nowFormatDate]['datalistDo']
                this.datalistNotdo = this.riliSchedule[this.nowFormatDate]['datalistNotdo']
            }
        }
        this.lunar = getLunar(this.nowDate.getFullYear(), this.nowDate.getMonth() + 1, this.nowDate.getDate()).dateStr
    },
    watch:{
        nowDate:{
            handler(newValue, oldValue) {
                console.log('日期变化', this.getDayStr())
                this.lunar = getLunar(this.nowDate.getFullYear(), this.nowDate.getMonth() + 1, this.nowDate.getDate()).dateStr
                if(this.riliSchedule[this.getDayStr()]){
                        this.datalistDo = this.riliSchedule[this.getDayStr()]['datalistDo']
                        this.datalistNotdo = this.riliSchedule[this.getDayStr()]['datalistNotdo']
                }else{
                    this.datalistDo = []
                    this.datalistNotdo = []
                }
            },
            
        },
      
    },
    methods: {
        changeColor(index,color){
        this.datalistDo[index].status = color
        this.updateData()
        },
        getDayStr(){
            return this.nowDate.getFullYear()+ '-' + (this.nowDate.getMonth() + 1) + '-' + this.nowDate.getDate()
        },
        del(index,e){
           
            if(e == 'do'){
                this.datalistDo.splice(index,1)
            }else{
                this.datalistNotdo.splice(index,1)
            }
            this.updateData()
        },
        add(){
            this.datalistDo.push({
                id: this.datalistDo.length,
                title:this.value,
                time:this.nowDate,
                status:0,
                checked: false
            })
            this.value = ""
            this.updateData()
            
        },
        checkBoxChange(index,e){
            console.log('checkBox数据变化')
            if(e == 'do'){
                var s = this.datalistDo.splice(index,1)[0]
                s['checked'] = true
                this.datalistNotdo.push(s)
            }else{
                var s = this.datalistNotdo.splice(index,1)[0]
                s['checked'] = false
                this.datalistDo.push(s)
            }
            this.updateData()
        },
        updateData(){
            console.log('更新数据',this.getDayStr())
            var dayData = {}
            dayData['datalistDo'] = this.datalistDo
            dayData['datalistNotdo'] = this.datalistNotdo

            this.riliSchedule[this.getDayStr()] = dayData
            localStorage.setItem("riliSchedule", JSON.stringify(this.riliSchedule));
        }
    }
}
</script>

<style lang="less">
.rili{
    height: 100%;
    display: flex;
    .left{
        flex:0 0 60%;
        .el-calendar-day{
            height: 60px;
        }
        #calendar{
            box-shadow: none;
            height: 500px;
            .days li{
                padding-bottom: 2%;
            }
        }
        .leftTitle{
            width: 180px;
            margin:20px auto 20px;
            border-radius: 20px;
            background: #E5E5E5;
            padding: 4px;
            li{
                flex:0 0 50%;
                color: #909399;
                text-align: center;
                cursor: pointer;
                padding: 6px 0;
            }
            .on{
                background-color: #B7B7B7;
                border-radius: 20px;
                color: #fff;
                
            }
        }
    }
    .right{
        flex:0 0 40%;
        background-color: #F5F5F5;
        padding-top: 40px;
        position: relative;
        overflow: hidden;
         *{
            margin:0;
            box-sizing: border-box;
            list-style-type: none;
        }
        .rightTop{
            .msg1{
                .p1{
                    font-size: 20px;
                    text-align: center;
                }
                .p2{
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    background-color: #367DF1;
                    border-radius: 10px;
                    position: relative;
                    text-align: center;
                    font-size: 40px;
                    color: #fff;
                    margin:20px auto;
                }
                .p2::before{
                    content:"";
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: #fff;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                }
                .p2::after{
                    content:"";
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: #fff;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }
                .p3{
                    font-size: 18px;
                    text-align: center;
                }
            }
        }
        .rightBottom::-webkit-scrollbar {
                width: 0.5rem;
                height: 0.5rem;
                background-color: darkgray;
                border-radius: 0.5rem;
            }
        .rightBottom{
            
            flex: 1 0 80%;
            // padding-bottom: 80px;
            overflow-y: auto;
            height: 280px;
            
            .top{
                
                .el-checkbox-group {
                    font-size: 0;
                    overflow-y: auto;
                    height: 19rem;
                    padding: 1rem;
                }
                overflow: auto;
                li{
                    display: flex;
                    justify-content: space-between;
                    align-items: top;
                    width: 96%;
                    margin:10px auto;
                    background-color: #fff;
                    padding: 10px 20px;
                    border-radius: 10px;
                    box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.1);
                    .list2{
                        flex:1 0 10%;
                        max-width: 80px;
                        display: flex;
                        justify-content: flex-end;
                        i{
                        font-size: 16px;
                        color: #909399;
                        cursor: pointer;
                        }
                        i:nth-child(1){
                        margin-right: 8px;
                        }
                    }
                    .list1{
                        flex:1 0 86%;
                        display: flex;
                        .check .el-checkbox__label{
                        text-decoration:line-through;
                        }
                        .el-checkbox {
                            display: flex;
                        }
                        .el-checkbox__label{
                        font-size: 16px;
                        color:#909399;
                        width: 85%;
                        white-space: normal;
                        text-align: start;
                        }
                        .el-checkbox__inner{
                        width: 24px;
                        height: 24px;
                        border-width: 3px;
                        border-radius: 0.5rem;
                        }
                        .el-checkbox__inner:after{
                        content:"";
                        width: 6px;
                        height: 12px;
                        left: 34%;
                        }
                        .checkbox1{
                        .el-checkbox__inner{
                            border-color: #E03131;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner{
                            background-color: #E03131;
                        }
                        }
                        .checkbox2{
                        .el-checkbox__inner{
                            border-color: #FFB000;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner{
                            background-color: #FFB000;
                        }
                        }
                        .checkbox3{
                        .el-checkbox__inner{
                            border-color: #4772FA;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner{
                            background-color: #4772FA;
                        }
                        }
                        .checkbox4{
                        .el-checkbox__inner{
                            border-color: #939393;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner{
                            background-color: #939393;
                        }
                        }
                        i{
                        font-size: 16px;
                        color: #909399;
                        }
                        .time{
                        padding-left: 32px;
                        margin-top: 4px;
                        font-size: 14px;
                        }
                    }
                }
            }
            .bottom{
                // position: fixed;
                // bottom: 20px;
                // left: 55%;
                // transform: translateX(-50%);
                // width: 80%;
                position: absolute;
                bottom: 20px;
                transform: translateX(-50%);
                left: 50% ;
                width: 96%;
                margin:0 auto;
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.1);
                i{
                color: #909399;
                font-size: 16px;
                cursor: pointer;
                }
                input{
                width: 90%;
                height: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                padding-left: 10px;
                font-size: 16px;
                }
            }
        }
    }
}
</style>