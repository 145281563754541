<template>
  <div >
    <el-dialog class="dialog" title="微信扫码登录" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" :before-close="close" top="2vh" width="fit-content">
        <div class="wechat-info">
        <img class="img-wechat" src="../assets/wechat.svg"/>
        <span class="font-weight-1000">使用微信扫一扫登录</span>
        </div>
         <div class="wechat-info"> <img class="info-img" :src="loginQR"/></div>
        <div>
          打开微信<span class="font-weight-1000"> 扫一扫</span>， <span class="font-weight-1000">关注</span>公众号后即可登录/注册
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {list} from "./list";
import axios from 'axios';
export default {
  name:'contactDialog',
  props:["dialogFormVisible","activeIndex", "addOrEditIndex" ],
  data() {
    return {
      isEdit:false,
      form:{
        url:'',
        value:'',
        type:'0',
        size: '1-1',
        valueBase64: ''
      },
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      limit:1,
      list:[],
      dialogVisible:false,
      allList:[],
      loginQR: require('../assets/wait.gif'),
      sceneStr: '',
      timerCount: 0,
      timer: '',
    }
  },
  created() {
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList.filter(e=>e.length===1)
    if(this.isEdit){
      console.log(this.form);
    }
    
            
    axios.get("https://ipage.cloud/wx/loginqrurl").then(res => {
      console.log(res.data)
      this.loginQR = res.data.data.ticket
      this.sceneStr = res.data.data.sceneStr
      this.isLoginTimer()
    })
  },
  methods:{
    isLoginTimer(){
      console.log('开启定时器')
      this.timer = setInterval(this.getIsLogin, 2000);
    },
    getIsLogin(){
      axios.get("https://ipage.cloud/wx/islogin/" + this.sceneStr).then(res => {
          console.log(res.data)
          console.log(res.data.status === 0)
          this.timerCount ++
          
          if(res.data.msg != '未登录'){
            clearInterval(this.timer);
             this.$message({
                message: '登录成功',
                type: 'success'
              });
              this.islogin = true
              localStorage.setItem("islogin",true)
              this.$emit("loadInfoFromCloud")
              this.close()
            
          }
          if(this.timerCount > 600){
            clearInterval(this.timer);
            location.reload();
          }
          console.log(this.timerCount)
        })
    },
    close(){
      console.log('关闭窗口')
      clearInterval(this.timer);
      this.$emit('updated')
    },
    onSubmit(){
      this.close()
    },
    handleChange(file){
      // console.log(file);
      // this.form.url = file.url
    },
    // 自定义上传方法 处理图片转化为base64
    beforeAvatarUpload(file){
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        let img_base64 = e.target.result.split(",")[1];
        // 自定义数组对象，传给后台的数据
        self.form.valueBase64 = img_base64
      }
      console.log(this.form);
    },
  }
}
</script>

<style>
.dialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
}
.appImg{
  width: 80px;
  height: 80px;
}
.el-dialog{
  margin-top: 6%;
}
.info-img{
  height: 17rem;
}
.el-form-item__content{
  display: flex;
}
.el-dialog__wrapper{
  align-items: center;
  display: flex;
}
.wechat-info{
  display: flex;
  align-items: center;
  justify-content: center;
}
.font-weight-1000{
  font-weight: 1000
}
</style>