<template>
  <div class="schedule">
      <div class = "week"> 星期三</div>
      <div class="day">29</div>
      <div class="no-schedule" v-if="dataList.length == 0">今日无日程</div>
      <div class = "list-schedule"  v-for="(item, index) in dataList.slice(0,3)" :key="index">
          <div class="li-left" :style="{'background-color': colorList[item.status]}">
          </div>
          <div class="li-right">{{item.title}}
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "schedule",
  data() {
    return {
        dataList: [],
        riliSchedule: [],
        datalistPre: [],
        checkedList: [],
        colorList: [ '#DCDFE6','#E03131','#FFB000','#4772FA','#939393']
    }
  },
  methods:{
    updated() {
      console.log('memo 更新')
      if (localStorage.getItem("riliSchedule")) this.riliSchedule = JSON.parse(localStorage.getItem("riliSchedule"))
      if(this.riliSchedule[this.getDayStr(new Date())]){
        this.dataList = this.riliSchedule[this.getDayStr(new Date())]['datalistDo']
        
      }
      console.log(this.dataList)

     
    },
    getDayStr(nowDate){
      return nowDate.getFullYear()+ '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
    },
  },
  created(){
    this.updated()
  },
}
</script>

<style>
.schedule {
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    overflow: hidden;
    border-radius: 1rem;
}
.week {
    display: flex;
    padding-top: 1rem;
    padding-left: 1rem;
    color: rgba(196,52,44,1);
    font-weight: bold;
}
.day{
    padding-left: 1rem;
    justify-content: start;
    align-items: start;
    display: flex;
    font-size: 2rem;
}
.no-schedule{
    padding-left: 1rem;
    display: flex;
    padding-top: 1rem;
    color: gray;
}
.list-schedule{
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1.5rem;

}
.li-left {
    width: 0.4rem;
    background-color: rgba(196,52,44,1);
    height: 1rem;
    display: inline;
    border-radius: 0.4rem;
}
.li-right {
    padding-left: 0.5rem;
    white-space: nowrap;
    width: 8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}
</style>