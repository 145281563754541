<template>
  <div>
	  <div class="search_box">
	  <div id = "search_source" class="source" @click="changeSearch">
	  <img  class="search_img" :src = "require('../assets/' + searchBox[searchIndex].image)"/>
	  <i>
		  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-dd1c147e=""><path fill="currentColor" d="m192 384 320 384 320-384z"></path></svg>
		</i>
	  </div>	  
	  <input class = "search_input" maxlength="63" placeholder="输入搜索内容" autofocus v-model="keyword" @keyup.enter="search"/>
	  
	  <div class="clear"></div>
	  <div class="button" @click="search">
		  <i>
		  	<svg class="search_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" data-v-ae6712f3=""><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="10" r="7"></circle><path d="M21 21l-6-6"></path></g></svg>
		  </i>
	  </div>
	  
	</div> 
	<div  class="animate__animated animate__fadeInUp search_box_total" v-if="searchBoxVisible">
		<div class="search_source" v-for="(item, index) in searchBox" :key="index" @click="changSearchIndex(index)">
			<img  class="search_img" :src = "require('../assets/' + item.image)"/>
			<div class="search_title">{{item.title}}</div>
		</div>
		
	</div> 
  </div>
</template>

<script>
export default {
	data(){
		return{
			keyword: '',
			searchBox : [
							{"title": '百度',"image":"baidu.svg","href": "https://www.baidu.com/s?wd="},
							{"title": 'Google',"image":"google.svg","href": "https://www.google.com/search?q="},
							{"title": 'Bing',"image":"bing.svg","href": "https://www.bing.com/search?q="},
							{"title": '搜狗',"image":"sougou.svg","href": "https://www.sogou.com/sogou?query="},
							{"title": '360搜索',"image":"360.svg","href": "https://www.so.com/s?q="},
							{"title": '知乎',"image":"zhihu.svg","href": "https://www.zhihu.com/search?type=content&q="},
							{"title": 'bilibili',"image":"bilibili.svg","href": "https://search.bilibili.com/all?keyword="},
							{"title": '小红书',"image":"xiaohongshu.svg","href": "https://www.xiaohongshu.com/search_result/?keyword="},
							{"title": '微博',"image":"weibo.svg","href": "https://s.weibo.com/weibo?q="},
							{"title": '微信',"image":"wechat.svg","href": "https://weixin.sogou.com/weixin?type=2&query="},
						],
			searchIndex: 0,
			searchBoxVisible: false
		}
	},
	created(){
		if(localStorage.getItem("searchIndex")) this.searchIndex = localStorage.getItem("searchIndex")
	},
	methods:{
		changeSearch(){
			this.searchBoxVisible = !this.searchBoxVisible
		},
		changSearchIndex(index){
			this.searchIndex = index
			localStorage.setItem("searchIndex", index)
			this.searchBoxVisible = false
		},
		search(){
			if(this.keyword.length > 0){
				window.open(this.searchBox[this.searchIndex].href + this.keyword, '_blank')
			}
		}
	}
}
</script>

<style  lang="less">
.search_box{
	border-radius: 10rem;
	background-color: rgba(255,255,255,1);
	align-items: center;
	justify-content: center;
	padding: 0.3rem 1rem;
	display: flex;
	
}
.search_box_total{
	border-radius: 1rem;
	background-color: rgba(255,255,255,1);
	align-items: center;
	padding: 0.3rem 1rem;
	display: flex;
	margin-top: -8.2rem;
	.search_source{
		padding: 0.5rem;
		img{
			width: 2rem;
			height: 2rem;
		}
		.search_title{
			font-size: 0.8rem;
		}
	}
	
}
.search_svg {
    width: 2rem;
	color: rgba(0,0,0,0.4);
    height: 2rem;
}
.source{
	display: flex;
	width: 3rem;
	float: left;
	align-items: center;
	img{
		width: 1.5rem;
	}
	i{
		width: 1rem;
		svg{
			color: gray;
		}
	}
	
}
.clear{
	display: inline;
}
.button{
	display: inline;
	float: right;
}
.search_input {
    display: inline;
    width: 100%;
    height: 90%;
    outline: none;
    border: none;
    float: left;
	font-size: 1rem;
	background-color: transparent;
	padding-left: 0.5rem;
}
</style>