<template>
  <div >
    <el-dialog class="dialog" title="导入书签" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" :before-close="close"  width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        
        
        <el-form-item label="书签文件:">
          <el-input size="small" v-model="fileName" ></el-input>
        </el-form-item>
        <el-form-item v-if="form.type === '0'">
          <el-upload
            ref="upload1"
            action=""
            list-type="text"
            clearFiles = "clearFiles"
            multiple
            :http-request="beforeAvatarUpload"
            :limit="limit"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {list,iconList} from "./list";
import {Base64} from 'js-base64'
export default {
  name:'bookMarksDialog',
  props:["dialogFormVisible","activeIndex", "addOrEditIndex" ],
  data() {
    return {
      isEdit:false,
      form:{
        url:'',
        value:'',
        type:'0',
        size: '1-1',
        valueBase64: ''
      },
      fileName: '',
      predefineColors: [
        'rgb(55, 33, 40)',
        'rgb(241, 196, 15)',
        'rgb(125, 172, 104)',
        'rgb(0, 113, 192)',
        'rgb(147, 38, 233)',
        'rgb(249, 98, 14)',
        'rgb(200, 172, 112)',
        'rgb(46, 204, 113)',
        'rgb(51, 197, 197)',

        'rgba(255, 69, 0, 1)',
        'rgba(255, 140, 0, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(144, 238, 144, 1)',
        'rgba(0, 206, 209, 1)',
        'rgba(30, 144, 255, 1)',
        'rgba(199, 21, 133, 1)',
        'rgba(255, 120, 0, 1)',
        "rgba(250, 212, 0, 1)",
        "rgba(0, 186, 189, 1)",
      ],
      limit:1,
      list:[],
      dialogVisible:false,
      allList:[],
      bookMarks: [],
    }
  },
  created() {
    console.log('')
    this.allList = JSON.parse(localStorage.getItem("list"))
    console.log('书签导入',this.allList)
  },
  methods:{
    close(){
      console.log('关闭窗口')
      this.$emit('updated')
    },
    onSubmit(){
      console.log("是否编辑", this.isEdit);
      console.log("编辑页面",this.activeIndex);
     
      this.close()
      console.log(this.allList);
    },
    handleChange(file){
      // console.log(file);
      // this.form.url = file.url
    },
    analysisBookmarksToArray(doms,bookMarkFolderName){
      var bookMarkFolder = []
      //console.log('分析数据中',this.bookMarks, doms,bookMarkFolderName)
      for(var i = 0;i < doms.length;i ++){
        if(doms[i].folder){
          this.analysisBookmarksToArray(doms[i].children,doms[i].name)
        }else{
          var bookmark = {}
          bookmark['id'] = 10
          bookmark['type'] = '1'
          bookmark['value'] = this.predefineColors[ Math.floor( Math.random( ) * this.predefineColors.length)]
          bookmark['content'] = doms[i]['name'].substring(0,2)
          bookmark['name'] = doms[i]['name']
          bookmark['url'] = doms[i]['url']
          bookmark['bgc'] = '#fff'
          bookmark['size'] = '1-1'
          bookmark['file'] = bookMarkFolderName  
          for(var icon_i = 0;icon_i < iconList.length;icon_i ++){
            if(bookmark['url'].indexOf(iconList[icon_i].url) > -1){
              bookmark['type'] = '0'
              bookmark['bgc'] = iconList[icon_i].bgc
              bookmark['value'] = iconList[icon_i].value
              bookmark['content'] = ''
            }
          }
           

          bookMarkFolder.push(bookmark)
        }
      }
      if(bookMarkFolder.length > 0){
          this.bookMarks.push(bookMarkFolder)
      }
      //console.log(bookMarkFolderName,bookMarkFolder.length ,bookMarkFolder,this.bookMarks)
    },
    // 自定义上传方法 处理图片转化为base64
    beforeAvatarUpload(file){
      let that = this
      console.log('书签文件',file)
      that.bookMarks = []
      this.fileName = file.file.name
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        try {
          let doms =  analysisBookmarksStr(Base64.decode(e.target.result.split(',')[1]));
          console.log('doms',doms)
          that.analysisBookmarksToArray(doms,'')
          console.log(that.bookMarks)
          that.allList[that.allList.length] = that.bookMarks
          localStorage.setItem("list", JSON.stringify(that.allList))
          
          that.$refs['upload1'].clearFiles()
          that.fileName = ""
          that.$emit('updated','add')
        } catch (error) {
            that.$message.error('书签文件类型错误，请正确导出书签后重试');
        }
        
      }
    },
    
  }
}
function walkBookmarksTree (root) {
    let result = []
    // 深度优先遍历
    let walk = (node, list) => {
        let els = node.children
        if (els && els.length > 0) {
            for (let i = 0; i < els.length; i++) {
                let item = els[i]
                // p标签或h3标签直接跳过
                if (item.tagName === 'P' || item.tagName === 'H3') {
                    continue
                }
                // 文件夹不用创建元素
                if (item.tagName === 'DL') {
                    walk(els[i], list)
                } else {// DT节点
                    let child = null
                    // 判断是否是文件夹
                    let children = item.children
                    let isDir = false
                    for(let j = 0; j < children.length; j++) {
                        if (children[j].tagName === 'H3' || children[j].tagName === 'DL') {
                            isDir = true
                        }
                    }
                    // 文件夹
                    if (isDir) {
                        child = {
                            name: item.tagName === 'DT' ? item.querySelector('h3') ? item.querySelector('h3').innerText : '' : '',
                            folder: true,
                            children: []
                        }
                        walk(els[i], child.children)
                    } else {// 书签
                        let _item = item.querySelector('a')
                        child = {
                            name: _item.innerText,
                            url: _item.href,
                            icon: _item.getAttribute('icon')
                        }
                    }
                    list.push(child)
                }
            }
        }
    }
    walk(root, result)
    return result
}
function analysisBookmarksStr(str) {
    let root = getBookmarksStrRootNode(str)
    return walkBookmarksTree(root)
}
function getBookmarksStrRootNode (str) {
    // 创建iframe
    let iframe = document.createElement('iframe')
    document.body.appendChild(iframe)
    iframe.style.display = 'none'
    // 添加书签dom字符串
    iframe.contentWindow.document.documentElement.innerHTML = str
    // 获取书签树根节点
    return iframe.contentWindow.document.querySelector('dl')
}
</script>

<style>
.dialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
}
.appImg{
  width: 80px;
  height: 80px;
}
.el-dialog{
  margin-top: 6%;
}
</style>