<template>
  <div class="hot">
      <div class="hot-title">
        <img class="hot-img" src = "../assets/weibo.svg"/>
        微博热搜
      </div>
      <ul class="hot-ul">
          <li>
            <div class="hot-content" v-for="(item, index) in weiboHotList.slice(0,4)" :key="index"  @click="openWeiboHot(item.url)">
              
              <div class="hot-content-title" :title="item.keyword">{{item.keyword}}</div>
              <div class="hot-hot hot-bao" v-if="item.isBoil">爆</div>
              <div class="hot-hot hot-new" v-if="item.isNew">新</div>
              <div class="hot-hot" v-if="item.isHot">热</div>
            </div>
          </li>
          <li class="ul-border">
            <div class="hot-content" v-for="(item, index) in weiboHotList.slice(4,7)" :key="index" @click="openWeiboHot(item.url)">
              <div class="hot-content-title" :title="item.keyword">{{item.keyword}}</div>
              <div class="hot-hot hot-bao" v-if="item.isBoil">爆</div>
              <div class="hot-hot hot-new" v-if="item.isNew">新</div>
              <div class="hot-hot" v-if="item.isHot">热</div>
            </div>
           
            
            <div class="hot-content hot-more" @click="openWeiboHot('https://s.weibo.com/top/summary?cate=realtimehot')">更多热搜
              <img src = "../assets/right_arrow_2.svg" style="width: 1rem;padding-left: 1rem;"/>
            </div>

          </li>
      </ul>
      
  </div>
</template>

<script>
import {weiboHot} from "./list";
import axios from 'axios';
export default {
  name: "hot",
  data() {
    return {  
      weiboHotList: [],
    }
  },
  methods:{
    openWeiboHot(url){
      window.open(url)
    }
  },
  created() {
    if (!localStorage.getItem("weiboHotList")) localStorage.setItem("weiboHotList", JSON.stringify(weiboHot));
    this.weiboHotList = JSON.parse(localStorage.getItem("weiboHotList"))

    axios.get('https://ipage.cloud/ipage/hot').then(
		  res => {
        console.log('网络请求成功',res)
        if(res.data.success == true){
          this.weiboHotList = res.data.data
          localStorage.setItem("weiboHotList", JSON.stringify(this.weiboHotList ));
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )

  },
}
</script>

<style>
.hot {
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    overflow: hidden;
    border-radius: 1rem;
}
.hot-title{
    justify-content: left;
    align-items: center;
    display: flex;
    padding-top: 0.7rem;
    padding-left: 1rem;
    font-weight: 700;
}
.hot-img{
    width: 2rem;
    padding-right: 0.5rem;
}
.hot-ul {
    position: relative;
    display: grid;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    grid-template-columns: repeat(auto-fill, 8rem);
    grid-template-rows: repeat(auto-fill, 6.4rem);
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0px;
    justify-content: start;
    padding-top: 0.2rem;
    padding-left: 1rem;
}
.hot-content-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hot-hot {
    display: inline;
    /* width: 0.7rem; */
    /* height: 0.7rem; */
    background-color: rgba(243,160,56,1);
    border-radius: 0.3rem;
    padding: 0.1rem 0.2rem;
    font-size: 0.9rem;
    color: white;
    margin-left: 0.5rem;
}
.hot-bao{
    background-color: rgba(177,25,12,1);

}
.hot-new{
    background-color: rgba(255,86,135,1);

}
.hot-content {
    font-size: 1.05rem;
    padding-top: 0.2rem;
    align-items: start;
    justify-content: start;
    display: flex;
    width: 8rem;
}
.ul-border {
    padding-left: 0.5rem;
    border-left: 0.1rem solid lightgray;
    margin-left: 1rem;
}
.hot-more{
  color: #E28D4A;
  align-items: center;
}
</style>