<template>
  <div id="app" >
    <router-view/>
  </div>
</template>
<script>
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';

export default {
  metaInfo: {
      meta: [{                 
        name: 'baidu-site-verification',
        content: 'codeva-iFMdeVML0J'},{                 
        name: 'keywords',
        content: '新标签页,精美日历、炫酷天气、每日头条、海量壁纸,主页美化,浏览器美化'},{                 
        name: 'Description',
        content: '自定义您新标签页上的网站和壁纸以及搜索引擎,创建和编辑属于您自己的浏览器标签页,精美日历、炫酷天气、每日头条、海量壁纸、常用网址随心订制'}]
  },
  created(){
    document.title = "iPage新标签页"

    const driver = new Driver({
      padding: 0
    }
    );
    const steps = [
        {
          element: "#index_time",
          popover: {
            title: "时间",
            description: "点击可切换极简模式",
            position: "bottom"
          }
        },{
          element: "#index_top",
          popover: {
            title: "右键菜单",
            description: "在整个页面任意空白处点击右键弹出右键菜单，可以添加图标、切换壁纸、立即备份、导入书签、添加和删除页面",
            position: "bottom-center"
          }
        },{
          element: "#search_source",
          popover: {
            title: "搜索引擎",
            description: "点击可切换多种搜索引擎",
            position: "bottom"
          }
        },{
          element: "#center",
          popover: {
            title: "中间框",
            description: "可添加使用多种小组件和图标，可以拖拽实现移动、合并文件夹等功能；右键可以编辑、删除；文件夹右键还有打开工作组功能；",
            position: "top-center"
          }
        },{
          element: "#bottomLeft",
          popover: {
            title: "Dock栏",
            description: "可从中间拖入脱出图标，删除需先拖入中间再右键删除",
            position: "top-center"
          }
        },{
          element: "#setting_my",
          popover: {
            title: "我的",
            description: "灰色为未登录，蓝色为已登录，点击登录后可实现备份、多端同步",
            position: "top-center"
          }
        },{
          element: "#setting_help",
          popover: {
            title: "联系我们",
            description: "点击联系我们，加入QQ交流群，获取支持和提供反馈",
            position: "top-right"
          }
        },{
          element: "#setting_feedback",
          popover: {
            title: "安装使用帮助",
            description: "点击打开安装和使用帮助页面，可以安装新标签页插件，设为主页等多种方式美化你的主页",
            position: "top-right"
          }
        }
    ]
    if(localStorage.getItem("start_info") != 1){
      setTimeout(() =>{
        driver.defineSteps(steps);
        driver.start();

        },1000)
        localStorage.setItem("start_info",1)
    }
    
  }
}
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?acab0a4d316526e3bc0083b05b2bb177";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>

<style>
html,body,#app{
  margin:0;
  padding:0;
  height: 100%;
}
#app {
  text-align: center;
  width: 100%;
  height: 100%;
  background-size:100% 100%;
  background-repeat: repeat;
}

div#driver-highlighted-element-stage,
div#driver-page-overlay {
    background: transparent !important;
    outline: 5000px solid rgba(0, 0, 0, .75);
}
</style>
