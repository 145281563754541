<template>  
<div class="player">
    <div class="player__header">
<audio class="audio" src="http://music.163.com/song/media/outer/url?id=28996919.mp3"></audio>
      <div class="player__img player__img--absolute slider">

        <button class="player__button player__button--absolute--nw playlist">

          <img src="../assets/playlist.svg" alt="playlist-icon">

        </button>

        <button @click = "play" class="player__button player__button--absolute--center play">

          <img src="../assets/play.svg" alt="play-icon" v-show="!isPlay">
          <img src="../assets/pause.svg" alt="pause-icon" v-show="isPlay">

        </button>

        <div class="slider__content">

          <img class="img slider__img" :src="musicListLocal[musicPlayIndex].imageUrl" alt="cover">

        </div>

      </div>

      <div class="player__controls">

        <button class="player__button back" @click="musicPlayBack">

          <img class="img" src="../assets/back.svg" alt="back-icon">

        </button>
        
        <p class="player__context slider__context">

          <strong class="slider__name">{{musicListLocal[musicPlayIndex].title}}</strong>
          <span class="player__title slider__title">{{musicListLocal[musicPlayIndex].singer}}</span>
        <audio class="audio" id ="audio" :src="musicListLocal[musicPlayIndex].songUrl"></audio>

        </p>

        <button class="player__button next"  @click="musicPlayNext">

          <img class="img" src="../assets/next.svg" alt="next-icon">

        </button>

        <div class="progres">

          <div class="progres__filled"></div>

        </div>

      </div>

    </div>

    <ul id = "songUl"  @mouseenter="musicEnter"  @mouseleave="musicLeave" class="player__playlist list">
      <li :id = "'song' + index" @click="playMusic(index)" class="player__song" v-for="(item, index) in musicListLocal" :key="index">
        <img class="player__img img" :src="item.imageUrl" alt="cover">
        <p class="player__context">
          <b class="player__song-name">{{item.title}}</b>
          <span class="flex">
            <span class="player__title">{{item.singer}}</span>
            <span class="player__song-time"></span>
          </span>
        </p>
      </li>


    </ul>

  </div>
</template>

<script>
import {musicList} from "./list";
import axios from 'axios';
export default {
  name: "hot",
  data() {
    return {  
      musicListLocal: [],
      musicPlayIndex: 0 ,
      isPlay: false,
    }
  },
  methods:{
    playAutoNext(){
      
      document.getElementById("songUl").scrollTop = document.getElementById("song" + this.musicPlayIndex).offsetTop  - document.getElementById("songUl").offsetTop 
      this.isPlay = false
      var that = this
      setTimeout(function() {
        that.isPlay = true
        document.querySelector("#audio").play()

      }, 500);
      document.querySelector("#audio").onerror = function(){that.musicPlayNext()}
      document.querySelector("#audio").addEventListener("ended",function(){
        that.musicPlayNext()
      })
      document.querySelector("#audio").addEventListener("timeupdate",function(){
          document.querySelector(".progres__filled").style.width = (this.currentTime / this.duration) * 100 + "%";
        })
    
    },
    musicPlayBack(){
      if(this.musicPlayIndex == 0){
        this.musicPlayIndex = this.musicListLocal.length - 1
      } else{
        this.musicPlayIndex = this.musicPlayIndex - 1
      }
      this.playAutoNext()
    },
    musicPlayNext(){
        this.musicPlayIndex = (this.musicPlayIndex + 1) % this.musicListLocal.length
        this.playAutoNext()
    },
    playMusic(index){
      this.musicPlayIndex = index
        this.playAutoNext()
    },
    play(){
      this.isPlay = !this.isPlay
      console.log("是否播放", this.isPlay)
      
      if(this.isPlay){
        this.playAutoNext()
      }else{
        document.querySelector("#audio").pause()
      }
    },
    openWeiboHot(url){
      window.open(url)
    },
    musicEnter(){
      console.log("鼠标进入")
      this.$emit("musicInfo",true)

    },
    musicLeave(){
      console.log("鼠标移出")
      this.$emit("musicInfo",false)
    }
  },
  created() {
    this.musicListLocal = musicList
    if (localStorage.getItem("musicListLocal"))   this.musicListLocal = JSON.parse(localStorage.getItem("musicListLocal"))
   
    
    axios.get('https://ipage.cloud/ipage/music').then(
		  res => {
        console.log('music网络请求成功',res)
        if(res.data.success == true){
          this.musicListLocal = res.data.data.songs
          localStorage.setItem("musicListLocal",JSON.stringify(this.musicListLocal));
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )

  },
}

</script>

<style>

.img {

    width: 100% ;
    display: block ;
    object-fit: cover ;

}

.list {

    margin: 0 ;
    padding: 0 ;
    list-style-type: none ;

}

.flex {

    display: flex ;
    align-items: center ;
    justify-content: space-between ;

}

.uppercase{
  
    text-transform: uppercase ;
  
}

.player {
    display: flex ;
    overflow: hidden ;
    border-radius: 1rem;
    height: 100%;
    font-size: 1.22em ;
    flex-direction: column ;
    background-color: white ;

}

.player__header {

    z-index: 1 ;
    gap: 0 .4em ;
    width: 100% ;
    height: 30%;
    display: flex;
    height: 5.85em ;
    flex-shrink: 0 ;
    position: relative;
    align-items: flex-start ;
    border-radius: inherit ;
    justify-content: flex-end ;
    background-color: white  ;
    padding: .95em 0.6em 0 .2em ;
    box-shadow: 0 2px 6px 1px #0000001f ;
    transition: height var(--cubic-header), box-shadow var(--duration), padding var(--duration) ease-in-out ;

}

.player__header.open-header {

    height: 100% ;
    padding-left: 0 ;
    padding-right: 0 ;
    box-shadow: unset ;

}

.player__img {

    width: 3.2em ;
    height: 3.2em ;
    border-radius: 1.32em ;

}

.player__img--absolute {
  
    top: 1.4em ;
    left: 1.2em ;
    position: absolute ;
    
}

.slider {

    flex-shrink: 0 ;
    overflow: hidden ;
    transition: width var(--cubic-header), height var(--cubic-header), top var(--cubic-header), left var(--cubic-header);
    
}

.slider.open-slider{

    top: 0 ;
    left: 0 ;
    width: 100% ;
    height: 14.6em ;

}

.slider__content {

    display: flex ;
    height: 100% ;
    transition: transform var(--cubic-slider);

}

.slider__img {

    filter: brightness(75%) ;

}

.slider__name, 
.slider__title {
    color: black;
    overflow: hidden ;
    white-space: nowrap ;

}

.text-wrap {

    display: block ;
    white-space: pre ;
    width: fit-content ;
    animation: text-wrap var(--duration-text-wrap) infinite ;

}

@keyframes text-wrap {

    75%{
      
        transform: translate3d(-51.5%, 0, 0) ;
      
    }

    100%{
      
        transform: translate3d(-51.5%, 0, 0) ;

    }
    
}

.player__button {

    all: unset ;
    z-index: 100 ;
    width: 2.5em ;
    height: 2.5em ;
    cursor: pointer ;

}

.playlist {

    transform: scale(0) ;
    transition: transform calc(var(--duration) / 2) ;
    
}

.slider.open-slider .playlist {

    transform: scale(1) ;
    transition: transform var(--cubic-play-list) ;


}

.player__button--absolute--nw {

    top: 5.5% ;
    left: 5.5% ;
    position: absolute ;
    
}

.player__button--absolute--center {
    
    top: 0 ;
    left: 0 ;
    right: 0 ;
    bottom: 0 ;
    margin: auto ;
    position: absolute ;

}
.player__controls {

    width: 77% ;
    gap: .5em 0 ;
    display: flex ;
    flex-wrap: wrap ;
    align-items: center ;
    will-change: contents ;
    align-content: center ;
    justify-content: center ;
    transition: transform var(--cubic-header) , width var(--cubic-header) ;

}

.player__controls.move {

    width: 88% ;
    transform: translate3d(-1.1em , calc(var(--parent-height) - 153%) , 0) ;
    
}

.player__context {
    text-align: start;
    margin: 0 ;
    width: 100% ;
    display: flex ;
    line-height: 1.8 ;
    flex-direction: column ;
    justify-content: center ;
    text-transform: capitalize ;

}

.slider__context {

    width: 56.28% ;
    cursor: pointer ;
    text-align: center ;
    padding-bottom: .2em ;
    will-change: contents ;
    transition: width var(--cubic-header) ;
    animation: calc(var(--duration) / 2) var(--cubic-slider-context) ;

}

@keyframes opacity {
   

    0% {

        opacity: 0 ;

    }

    90%{

        opacity: 1 ;

    }

}

.player__controls.move .slider__context{
    
    width: 49.48% ;

}

.player__title {

    font-size: .7em ;
    font-weight: bold ;
    color: #00000086 ;

}

.progres {

    width: 90% ;
    height: .25em ;
    cursor: pointer ;
    border-radius: 1em ;
    background-color: #e5e7ea ;
    transition: width var(--cubic-header) ;

}

.player__controls.move .progres{

    width: 98% ;
    
}

.progres__filled {

    width: 0% ;
    height: 100% ;
    display: flex ;
    position: relative ;
    align-items: center ;
    border-radius: inherit ;
    background-color: #78adfe ;
    
}

.progres__filled::before {

    right: 0 ;
    width: .35em ;
    content: " " ;
    height: .35em ;
    border-radius: 50% ;
    position: absolute ;
    background-color: #5781bd ;
    
}

.player__playlist {

    height: 70% ;
    overflow: auto ;   
    padding: 1.05em .9em 0 1.2em ; 

}

.player__playlist::-webkit-scrollbar {
    
    width: 0 ;

}

.player__song {

/*     gap: 0 .65em ; */
    display: flex ;
    cursor: pointer ;
    margin-bottom: .5em ;
    padding-bottom: .7em ;
    border-bottom: .1em solid #d8d8d859 ;

}

.player__song .player__context {

    line-height: 1.5 ;
    margin-left: .65em ;

}

.player__song-name {
    color: black;
    font-size: .88em ;

}

.player__song-time {

    font-size: .65em ;
    font-weight: bold ;
    color: #00000079 ; 
    height: fit-content ;
    align-self: flex-end ;

}

.audio {

    display: none !important ;

}
</style>