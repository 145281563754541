<template>
  <div >
    
    <el-dialog class="addappdialog" 
    :width="isMobile ? '90%' : '70%'" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" 
    :before-close="close"  top = "10vh">
      <div slot="title" class="header-title">
       <span  class="name">{{isEdit?'编辑图标':'添加图标'}}</span>
       <el-input v-model="searchInput" placeholder="请输入搜索内容" 
        prefix-icon="el-icon-search" 
        style="width: 30%;height: 10%;margin-left: 9%;"></el-input>

    </div>

      <div class="left">
        <div class="left-li" @click="rightType = 0">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M16.66 4.52l2.83 2.83l-2.83 2.83l-2.83-2.83l2.83-2.83M9 5v4H5V5h4m10 10v4h-4v-4h4M9 15v4H5v-4h4m7.66-13.31L11 7.34L16.66 13l5.66-5.66l-5.66-5.65zM11 3H3v8h8V3zm10 10h-8v8h8v-8zm-10 0H3v8h8v-8z" fill="currentColor"></path></svg>
          <span>添加小组件</span>
        </div>
        <div class="left-li" @click="rightType = 1">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm-5.5-2.5l7.51-3.49L17.5 6.5L9.99 9.99L6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1s-1.1-.49-1.1-1.1s.49-1.1 1.1-1.1z" fill="currentColor"></path></svg>
          <span>添加图标</span>
        </div>
        <div class="left-li" @click="rightType = 2">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M20.97 7.27a.996.996 0 0 0 0-1.41l-2.83-2.83a.996.996 0 0 0-1.41 0l-4.49 4.49l-3.89-3.89c-.78-.78-2.05-.78-2.83 0l-1.9 1.9c-.78.78-.78 2.05 0 2.83l3.89 3.89L3 16.76V21h4.24l4.52-4.52l3.89 3.89c.95.95 2.23.6 2.83 0l1.9-1.9c.78-.78.78-2.05 0-2.83l-3.89-3.89l4.48-4.48zM5.04 6.94l1.89-1.9L8.2 6.31L7.02 7.5l1.41 1.41l1.19-1.19l1.2 1.2l-1.9 1.9l-3.88-3.88zm11.23 7.44l-1.19 1.19l1.41 1.41l1.19-1.19l1.27 1.27l-1.9 1.9l-3.89-3.89l1.9-1.9l1.21 1.21zM6.41 19H5v-1.41l9.61-9.61l1.3 1.3l.11.11L6.41 19zm9.61-12.44l1.41-1.41l1.41 1.41l-1.41 1.41l-1.41-1.41z" fill="currentColor"></path></svg>
          <span>自定义图标</span>
        </div>
      </div>
      <div class="right" id = "iconRight" v-on:scroll="handleScroll">
        <div v-if ="rightType == 0">
          <div class="group">
              <div @click="addCompoment(index)" v-for="(item, index) in compomentList" :key="index" :class="['icon-' + item.size]">
                <memo ref = "memoDialog" v-if = "item.name =='备忘录'"/>
                <schedule ref = "scheduleDialog" v-if = "item.name =='日历'"/>
                <todo-list ref = "todoListDialog" v-if = "item.name =='列表'"/>
                <hot  v-if = "item.name =='热搜'"/>
                <music v-if = "item.url =='music'"/>
                <chatgpt  v-if = "item.url =='chatgpt'"/>
                <!-- <div v-if="item.comp === 2" class="item"></div> -->
                <div v-if="item.name === '时间'" class="timeBox icon-2-2">
                  <div>
                    <div class="time1">20:00</div>
                    <div class="time2">5/1星期一</div>
                  </div>
                </div>
                <weather ref = 'weather'  v-if="item.name == '天气预报'" />
                
                <holiday ref="holiday" v-if ="item.name == '下一个假期'"  />
               <div class="item-name"> {{item.name}}</div>
              </div>
          </div>
        </div>
        
        <div v-if ="rightType == 1" class="group">
              
              <div @click="addApp(index)" v-for="(item, index) in iconPreList" :key="index" :class="['icon-' + item.size]">
                <img :src="require('../assets/icon/' + item.value)"  class="appImg"  :style="{backgroundColor: item.bgc}"  alt="">
          
               <div class="item-name"> {{item.name}}</div>
              </div>
        </div>
        <el-form v-if ="rightType == 2" ref="form" :model="form" label-width="80px" width = "80%">
          <el-form-item v-if="isEdit" label="当前图标:">
            <el-select size="small" v-model="form" placeholder="请选择编辑的图标名称">
              <el-option v-for="(item,index) in list" :key="index" :label="item[0].name" :value="item[0]"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址:">
            <el-input size="small" v-model="form.url"></el-input>
          </el-form-item>
          <el-form-item label="名称:">
            <el-input size="small" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="图标类型:">
            <el-select size="small" v-model="form.type" placeholder="请选择图标类型">
              <!-- <el-option label="在线图标" value="2"></el-option> -->
              <el-option label="文字图标" value="1"></el-option>
              <el-option label="图片图标" value="0"></el-option>
            </el-select>
          </el-form-item>
          
          <div v-if="form.type === '2'">
            <span class="icon-span">
                <div v-for="url,index in iconsWeb"  :key="index" style="position: relative;overflow: hidden">
                  <img :src="url" style="width: 80px; height: 80px;margin: 20px 20px 0 0" @click="iconWebSelect = index"/>
                  <img src="../assets/sellect.svg" style="width: 20px;position: absolute;bottom: 0px;left: 60px;" v-show="iconWebSelect == index"/>
                </div>
            </span>
          </div>
          <el-form-item v-if="form.type === '1'" label="图标颜色:">
            <el-color-picker
              size="small"
              v-model="form.value"
              show-alpha
              :predefine="predefineColors">
            </el-color-picker>
          </el-form-item>
          <el-form-item v-if="form.type === '1'" label="图标文字:">
            <el-input size="small" v-model="form.content"></el-input>
          </el-form-item>
          
          <el-form-item v-if="form.type === '0'">
            <el-upload
              ref="upload1"
              action=""
              list-type="picture-card"
              multiple
              :http-request="beforeAvatarUpload"
              :limit="limit"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
        </el-form>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {list,compomentList,iconPreList} from "./list";
import memo from "@/components/memo.vue"
import Schedule from '@/components/schedule.vue';
import TodoList from '@/components/todoList.vue';
import Hot from '@/components/hot.vue';
import Weather from '@/components/weather.vue';
import Music from './music.vue';
import Chatgpt from '@/components/chatgpt.vue';
import holiday from '@/components/holiday.vue';
import axios from 'axios';
export default {
  name:'appDialog',
  props:["dialogFormVisible","activeIndex", "addOrEditIndex","isMobile"],
  components: { memo, Schedule, TodoList, Hot, Weather, Music, Chatgpt, holiday},
  data() {
    return {
      isEdit:false,
      rightType: 0,
      compomentList: [],
      iconPreList: [],
      form:{
        url:'',
        value:'rgba(30, 144, 255, 1)',
        type:'1',
        size: '1-1',
        valueBase64: ''
      },
      searchInput: '',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      limit:1,
      list:[],
      dialogVisible:false,
      allList:[],
      iconScrollBottom: true,
      pageSize: 0,
      iconsWeb: [],
      iconWebSelect: -1,
    }
  },
  mounted(){
  },
  created() {
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList.filter(e=>e.length===1)
    this.compomentList = compomentList
    this.iconPreList = iconPreList
    console.log('compomentList',this.compomentList)
  },
  watch:{
    searchInput(){
      if(this.searchInput == ""){
        this.iconPreList = iconPreList
      }else{
        axios.get("https://ipage.cloud/ipage/iconsearch/" + this.searchInput).then(
          res => {
            console.log('icon网络请求成功',res)
            if(res.data.status == 0){
              this.rightType = 1
              this.iconPreList = res.data.data
              console.log(this.iconPreList)
            }
          }
        )
      }
      
    },
    'form.name':{
      handler(newValue, oldValue) {
        this.form.content = newValue
      }
    },
    form:{
      handler(newValue, oldValue) {
        console.log("表单变化",newValue, oldValue);
        
        if(this.form.type == 2){
        axios.post("https://localhost/ipage/icon/web/", {"weburl": this.form.url}).then(
          res => {
            console.log('icon网络请求成功',res)
            if(res.data.status == 0){
              this.iconsWeb = res.data.data
            }else{
              this.iconsWeb = []
              this.iconWebSelect = -1
            }
          }
        )
      }
      },
      deep: true
      
    }
  },
  methods:{
    handleScroll(){
      var iconRight = document.querySelector("#iconRight");   // 获取滚动条的dom
      // 获取距离顶部的距离
      var scrollTop = iconRight.scrollTop;
      // 获取可视区的高度
      var windowHeight = iconRight.clientHeight;
      // 获取滚动条的总高度
      var scrollHeight = iconRight.scrollHeight;
       if (Math.ceil(scrollTop + windowHeight) >= scrollHeight && this.rightType == 1) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        console.log('滑动到底部')
        if(this.iconScrollBottom){
          
          this.iconScrollBottom = false
          axios.post("https://ipage.cloud/ipage/icon/" + this.pageSize).then(
            res => {
              console.log('icon网络请求成功',res)
              if(res.data.status == 0){
                this.iconPreList = this.iconPreList.concat(res.data.data)
                console.log(this.iconPreList)
                this.iconScrollBottom = true
                this.pageSize = this.pageSize + 1
              }
            }
          )
        }
      }else{
        console.log('未滑动到底部')

      }
    },
    close(){
      console.log('关闭窗口')
      this.$emit('updated')
    },
    addCompoment(index){
      this.allList[this.activeIndex].push([this.compomentList[index]])
      this.$message({
          message: '小组件添加成功',
          type: 'success'
        });
      localStorage.setItem("list", JSON.stringify(this.allList))
      this.$emit('updated',"addAppContinue")
    },
    addApp(index){
      this.allList[this.activeIndex].push([this.iconPreList[index]])
      this.$message({
          message: '图标添加成功',
          type: 'success'
        });
      localStorage.setItem("list", JSON.stringify(this.allList))
      this.$emit('updated',"addAppContinue")
      axios.get("https://ipage.cloud/ipage/icon/add/" + this.iconPreList[index].id)
    },
    onSubmit(){
      console.log("是否编辑", this.isEdit);
      console.log("编辑页面",this.activeIndex);
      if(this.isEdit) {
        this.allList.forEach((e,i)=>{
          if(e[0].id === this.form.id){
            console.log(111,e[0].id);
            this.allList[i] = [this.form]
          }
        })
        console.log(this.allList);
        localStorage.setItem("list", JSON.stringify(this.allList))
        this.close()
        return
      }
      
      console.log(this.form);
      console.log(this.activeIndex);
      this.form.id = Math.floor(Math.random()*10000+100)
      //this.form.value = this.iconsWeb[this.iconWebSelect]

      console.log('添加图标',this.form)
      this.allList[this.activeIndex].push([JSON.parse(JSON.stringify(this.form))])
      localStorage.setItem("list", JSON.stringify(this.allList))
      this.close()
      console.log(this.allList);
    },
    handleChange(file){
      // console.log(file);
      // this.form.url = file.url
    },
    // 自定义上传方法 处理图片转化为base64
    beforeAvatarUpload(file){
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        let img_base64 = e.target.result.split(",")[1];
        // 自定义数组对象，传给后台的数据
        self.form.valueBase64 = img_base64
      }
      console.log(this.form);
    },
  }
}
</script>

<style lang="less">
.addappdialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
  .icon-span{
    display: grid;
    justify-content: start;
    /* width: 1000px; */
    grid-template-columns: repeat(auto-fill,6rem);
    grid-template-rows: repeat(auto-fill,6rem);
  }
  .el-dialog{
    width: 70%;
    height: 80%;
    overflow: hidden;
  }
  .el-dialog__body{
    display: flex;
    padding: 0;
    height: 87%;
  }
  .el-form-item__label{
    text-align: left;
  }
  
.left{
  flex: 1 0 16%;
  padding-left: 1rem;
  padding-top: 1rem;
  .left-li{
    display: flex;
    font-size: 1.2rem;
    padding-bottom: 1rem;
    svg{
      width: 1.2rem;
    }
    span{
      padding-left: 0.5rem;
      font-weight: 1000;
    }
  }
}
.right::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
      background-color: darkgray;
      border-radius: 0.5rem;
  }
.right{
  flex: 1 0 80%;
  overflow: auto;
  .el-form{
    width: 80%;
  }
  .group{
    display: grid;
    grid-template-columns: repeat(auto-fill,4rem);
    grid-template-rows: repeat(auto-fill,4rem);
    grid-auto-flow: dense;
    grid-gap: 1.5rem;
    .icon-1-1{
      grid-row: span 1;
      grid-column: span 1;
      width: 4rem;
      height: 4rem;
      border-radius:1rem;
      .appImg{
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
    }
    .item-name{
      position: relative;
      z-index: 1;
      color:black;
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}




.icon-2-1{
	grid-row:span 2;
	grid-column:span 1;
  border-radius:1rem;
}
.icon-2-2{
	grid-row:span 2;
	grid-column:span 2;
  width: 9.5rem;
  height: 9.5rem;
  border-radius:1rem;
}
.icon-1-2{
	grid-row:span 1;
	grid-column:span 2;
  border-radius:1rem;
}
.icon-2-4{
  grid-row: span 2;
  grid-column: span 4;
  width: 20.5rem;
  height: 9.5rem;
  border-radius:1rem;
}
.icon-4-2{
  grid-row: span 4;
  grid-column: span 2;
  width: 9.5rem;
  height: 20.5rem;
  border-radius:1rem;
}
.icon-4-4{
  grid-row: span 4;
  grid-column: span 4;
  width: 20.5rem;
  height: 20.5rem;
  border-radius:1rem;
}


.timeBox{
  background-color: #282525;
  display: flex;
  align-items: center;
  >div{
    flex: 1;
    height: 80px;
    color: white;
    .time1{
      font-size: 3.125rem;
      display: flex;
      justify-content: center;
    }
    .time2{
      font-size: 0.9375rem;
      display: flex;
      justify-content: center;
    }
  }
}
}
</style>