<template>
  <div >
    <el-dialog class="dialog" title="联系我们" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" :before-close="close" top="2vh" width="fit-content">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item v-if="isEdit" label="当前图标:">
          <el-select size="small" v-model="form" placeholder="请选择编辑的图标名称">
            <el-option v-for="(item,index) in list" :key="index" :label="item[0].name" :value="item[0]"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="ID:" v-if="idShow">
          <el-tag>{{userid}}</el-tag>
        </el-form-item>
        <el-form-item label="邮箱:">
          <el-tag>3142439568@qq.com</el-tag>
        </el-form-item>
        <el-form-item label="QQ群:">
          <img class="info-img-contact" src="../assets/user/qq_group_1.jpg"/>
        </el-form-item>
        <el-form-item  label="赞赏我们:" class="form-zanshang">
          <el-tag>微信</el-tag>
          <img  class="info-img-contact" src="../assets/user/wechat.jpg"/>
          <el-tag>支付宝</el-tag>
          <img class="info-img-contact"   src="../assets/user/alipay.jpg"/>
        </el-form-item>
       
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {list} from "./list";
import axios from 'axios';


export default {
  name:'contactDialog',
  props:["dialogFormVisible","activeIndex", "addOrEditIndex" ],
  data() {
    return {
      isEdit:false,
      form:{
        url:'',
        value:'',
        type:'0',
        size: '1-1',
        valueBase64: ''
      },
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      limit:1,
      list:[],
      dialogVisible:false,
      allList:[],
      idShow: false,
      userid: ''
    }
  },
  created() {
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList.filter(e=>e.length===1)
    if(this.isEdit){
      console.log(this.form);
    }

    axios.get('https://ipage.cloud/get/userid').then(
		  res => {
        console.log('网络请求成功',res)
        if(res.data.status == 0){
          this.idShow = true
          this.userid = res.data.data
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )
  
  },
  methods:{
    close(){
      console.log('关闭窗口')
      
      this.$emit('updated')
    },
    onSubmit(){
      this.close()
    },
    handleChange(file){
      // console.log(file);
      // this.form.url = file.url
    },
    // 自定义上传方法 处理图片转化为base64
    beforeAvatarUpload(file){
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        let img_base64 = e.target.result.split(",")[1];
        // 自定义数组对象，传给后台的数据
        self.form.valueBase64 = img_base64
      }
      console.log(this.form);
    },
  }
}
</script>

<style>
.dialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
}
.appImg{
  width: 80px;
  height: 80px;
}
.el-dialog{
  margin-top: 6%;
}
.info-img-contact{
  height: 10rem;
}
.el-form-item__content{
  display: flex;
}
.el-dialog__wrapper{
  align-items: center;
  display: flex;
}
</style>