<template>
  <div >
    <el-dialog class="dialog" title="编辑图标" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" :before-close="close"  width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        
        <el-form-item label="名称:">
          <el-input size="small" v-model="appDetail.name"></el-input>
        </el-form-item>
        <el-form-item label="地址:">
          <el-input size="small" v-model="appDetail.url"></el-input>
        </el-form-item>
        <el-form-item label="图标类型:">
          <el-select size="small" v-model="appDetail.type" placeholder="请选择图标类型">
            <el-option label="图片图标" value="0"></el-option>
            <el-option label="文字图标" value="1"></el-option>
            <!-- <el-option label="在线图标" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item v-if="appDetail.type === '1'" label="图标颜色:">
          <el-color-picker
            size="small"
            v-model="appDetail.value"
            show-alpha
            @change = "colorChange"
            :predefine="predefineColors">
          </el-color-picker>
        </el-form-item>
        <el-form-item v-if="appDetail.type === '1'" label="图标文字:">
          <el-input size="small" v-model="appDetail.content"></el-input>
        </el-form-item>
        
        <el-form-item v-if="appDetail.type === '0' && haveIcon">
          <el-upload
            ref="upload1"
            action=""
            list-type="picture-card"
            multiple
            :http-request="beforeAvatarUpload"
            :limit="limit"
            
            :file-list = "[{name: 'logo', url: appDetail.value == '' ? 'data:image/png;base64,'+appDetail.valueBase64 : require('../assets/icon/' + appDetail.value) }]"
          >
            <i class="el-icon-plus"></i>
            
          </el-upload>
        </el-form-item>
        <el-form-item v-if="appDetail.type === '0' && !haveIcon">
          <el-upload
            ref="upload1"
            action=""
            list-type="picture-card"
            multiple
            :http-request="beforeAvatarUpload"
            :limit="limit"
          >
            <i class="el-icon-plus"></i>
            
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {list} from "./list";
import $ from 'jquery'
export default {
  name:'appDialog',
  props:["dialogFormVisible","activeIndex", "appEditIndex","appEditInfileIndex" ],
  data() {
    return {
      isEdit:false,
      form:{
        url:'',
        value:'',
        type:'0',
        valueBase64: '',
      },
      haveIcon: false,
      appDetail:{},
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      limit:1,
      list:[],
      dialogVisible:false,
      allList:[]
    }
  },
  created() {
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList.filter(e=>e.length===1)
    this.appDetail = this.allList[this.activeIndex][this.appEditIndex][this.appEditInfileIndex]
    this.haveIcon = this.appDetail.type == '0'
    this.getstyle('.el-upload-list__item-thumbnail').backgroundColor = this.appDetail.bgc
    if(this.isEdit){
      console.log(this.form);
    }
  },
  methods:{
    colorChange(color){
      console.log('颜色变化',color)
      this.appDetail.value = color
    },
    getstyle(sname) {
      for (var i=0;i<document.styleSheets.length;i++) {
        var rules;
        if (document.styleSheets[i].cssRules) {
          rules = document.styleSheets[i].cssRules;
        } else {
          rules = document.styleSheets[i].rules;
        }
        for (var j=0;j<rules.length;j++) {
          if (rules[j].selectorText == sname) {
          //selectorText 属性的作用是对一个选择的地址进行替换.意思应该是获取RULES[J]的CLASSNAME.有说错的地方欢迎指正
          return rules[j].style;
        }
      }
      }
    },
    close(){
      console.log('关闭窗口')
      this.$message({
          message: '图标修改成功',
          type: 'success'
        });
      this.$emit('updated',this.appDetail)
    },
    onSubmit(){
      if(this.appDetail.value.startsWith('#') || this.appDetail.value.startsWith('rgb') ){
        this.appDetail.type = '1'
      }
      this.allList[this.activeIndex][this.appEditIndex][this.appEditInfileIndex] = this.appDetail
      console.log(this.allList);
      localStorage.setItem("list", JSON.stringify(this.allList))
      this.close()
    },
    handleChange(file){
      // console.log(file);
      // this.form.url = file.url
    },
    // 自定义上传方法 处理图片转化为base64
    beforeAvatarUpload(file){
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = function (e) {
        let img_base64 = e.target.result.split(",")[1];
        // 自定义数组对象，传给后台的数据
        self.appDetail.value = ''
        self.appDetail.valueBase64 = img_base64
      }
    },
  }
}
</script>

<style>
.dialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
}
.appImg{
  width: 80px;
  height: 80px;
}
.el-dialog{
  margin-top: 6%;
}
.el-upload-list__item-thumbnail{
  background-color: brown;
}
</style>