<template>
  <div class="holiday">
    <div class = "content">
      <div class="title" >{{holiday.name}}</div>
      <div class="count" > 
        <div class = "cday">{{count}}</div>
        <div class="cTian">天</div>
      </div>
      <div class="hday" >{{date}} </div>
    </div>
  </div>
</template>

<script>
import {weiboHot} from "./list";
import axios from 'axios';
export default {
  name: "holiday",
  data() {
    return {  
      weiboHotList: [],
      holiday: '',
      count: 0,
      date: '',
    }
  },
  methods:{
    openWeiboHot(url){
      window.open(url)
    },
    TowTimeDayFormat(time1, time2) {
      let totalDays,diffDate
      var day1 = Date.parse(time1)
      var day2 = Date.parse(time2)
      // 将两个日期都转换为毫秒格式，然后做差
      diffDate = Math.abs(day1 - day2) // 取相差毫秒数的绝对值
      totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整 
      return totalDays    // 相差的天数
    },
  },
  created() {
    if (localStorage.getItem("holiday")) {
      this.holiday = JSON.parse(localStorage.getItem("holiday"))
      var hDate = this.holiday['vacation'].split('|')
      if(hDate.length > 1){
        this.date = hDate[0].split('-')[1] + '.' + hDate[0].split('-')[2] + '-' + hDate[hDate.length - 1].split('-')[1] + '.' + hDate[hDate.length - 1].split('-')[2] 
      }
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      
      this.count = this.TowTimeDayFormat(new Date( `${year}-${month}-${day}` ), new Date(hDate[0]))
    }
    
    axios.get('https://ipage.cloud/ipage/holiday').then(
		  res => {
        console.log('holiday获取成功',res)
        if(res.status == 200){
          this.holiday = res.data
          localStorage.setItem("holiday", JSON.stringify( this.holiday ));
          hDate = this.holiday['vacation'].split('|')
          if(hDate.length > 1){
            this.date = hDate[0].split('-')[1] + '.' + hDate[0].split('-')[2] + '-' + hDate[hDate.length - 1].split('-')[1] + '.' + hDate[hDate.length - 1].split('-')[2] 
          }
          
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          this.count = this.TowTimeDayFormat(new Date(`${year}-${month}-${day}`), new Date(hDate[0]))
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )

  },
}
</script>

<style scoped lang="less">
.holiday {
    width: 100%;
    height: 100%;
    background-color: rgb(255,202,50);
    color: black;
    overflow: hidden;
    border-radius: 1rem;
    .content{
      margin-left: 2rem;
      margin-top: 1rem;
      justify-content: left;
      .title{
        justify-content: left;
        display: flex;
      }
      .count{
        justify-content: left;
        display: flex;
        align-items: flex-end;
        .cday{
          font-size: 3.5rem;
          font-family: fantasy;
        }
        .cTian{
          padding-bottom: 0.6rem;
          padding-left: 0.5rem;

        }

      }
      .hday{
        justify-content: left;
        display: flex;
      }
    }
}

</style>