<template>
  <div style="width:100%;height:100%;position:relative" >
   
    <draggable @add = "addMain" @remove = "remove" class="wrapper" :list="list" :delay = 'isMobile ? 200 : 5' 
      :fallbackTolerance="3" :group="groupA" @update="datadragEnd" animation="300" :sort= "true">
      <transition-group class="itemAll ulGrid">
        <div @contextmenu.stop ="appMeun(index, item.length == 1 ? 0: -1)"  v-for="(item, index) in list" :class="['item', 'swiper-no-swiping',  item.length > 1 ? 'icon-2-2' : item[0].size ? 'icon-' + item[0].size : 'icon-1-1' ]" :key="index" class="item swiper-no-swiping" :style="{backgroundColor:item.length!==1?'#4c514e87':item[0].type == 1?item[0].value:''}"
            :draggable ="item[0].name == '天气预报' ? false : true"   @click.prevent="jump(item[0].type,item,index,item[0].url)">
          
         <memo ref = "memoDialog" :memoDialogVisible = "memoDialogVisible" :memoList = "memoList"  v-if = "item[0].name =='备忘录'"/>
         <schedule ref = "scheduleDialog" v-if = "item[0].name =='日历'"/>
         <todo-list ref = "todoListDialog" v-if = "item[0].name =='列表'"/>
         <hot  v-if = "item[0].name =='热搜'"/>
         <chatgpt  v-if = "item[0].url =='chatgpt'"/>
         <music v-if = "item[0].url =='music'" @musicInfo = "musicInfo"/>
          <!-- <div v-if="item[0].comp === 2" class="item"></div> -->
          <div id="time" v-if="item[0].name === '时间'" class="timeBox icon-2-2">
            <div>
              <div class="time1">{{time}}</div>
              <div class="time2">{{getDate().month+'/'+getDate().dates+' '+getDate().day}}</div>
            </div>
          </div>
        <weather ref = 'weather'  v-if="item[0].name == '天气预报'"  />
        <holiday ref="holiday" v-if ="item[0].name == '下一个假期'"  />
          <div class = "div-img" v-if="item.length === 1&&item[0].type == 0" :style="{backgroundColor: item[0].bgc}">
            <img  :src="item[0].value =='' ?  'data:image/png;base64,'+item[0].valueBase64 : require('../assets/icon/' + item[0].value)"  class="appImg"  :style="{backgroundColor: item[0].bgc}"  alt="">
          </div>
          <div class = "div-img" v-if="item[0].type == 2" :style="{backgroundColor: item[0].bgc}">
            <img  :src="item[0].value"  class="appImg"  :style="{backgroundColor: item[0].bgc}"  alt="">
          </div>
          <span class="content" v-if="item.length == 1 && item[0].type == 1" :style="{backgroundColor: item[0].value, fontSize: item[0].size == '2-2' ? '2rem': '1rem'}">{{ item[0].content }}</span>
          <draggable
            :group="groupB"
            class="wrapper-in"
            :list="item"
            @update="datadragEndSub(index, $event)"
            animation="300"
            v-if="item[0].type != 3"
            @add="add(index,$event)" 
            @clone = "clone"
            :delay = 'isMobile ? 200 : 5'
            @remove="addOut"
          >
            <transition-group class="itemAll-in">
              <div v-for="(item2, index2) in item.filter((e,i)=>i<9)" :key="index2">
                <div v-if="item.length !== 1" class="item-in" :style="{backgroundColor:item2.type == 1?item2.value:'#00000000'}">
                  <img v-if="!item2.content &&item2.type == 0" class="appSmallImg" :src="item2.value =='' ?  'data:image/png;base64,'+item2.valueBase64 : require('../assets/icon/' + item2.value)" :style="{backgroundColor: item2.bgc}" alt="">
                  <span class="content" v-if="item2.type == 1" :style="{backgroundColor: item2.value}">{{ item2.content }}</span>
                  <img v-if="item2.type == 2" class="appSmallImg" :src="item2.value" :style="{backgroundColor: item2.bgc}" alt="">
                  
                </div>
              </div>
            </transition-group>
          </draggable>
          <div class="item-name">{{ item.length>1? item[0].file ? item[0].file: '文件夹':item.length?item[0].name:item.name }}</div>
        </div>
      </transition-group>
    </draggable>
    <!-- <draggable class="wrapper-bottom swiper-no-swiping" :list="bottomList" :group="groupA" @update="datadragEnd" animation="300" :sort= "true">
      <transition-group class="itemAll">
        <div v-for="(item, index) in bottomList" :key="index" class="item" :style="{backgroundColor:item[0].value}">
          <img v-if="item.length === 1&&item[0].type == 0" class="appImg" :src="'data:image/png;base64,'+item[0].value" alt="">
          <span class="content" v-if="item.length == 1 && item[0].type == 1">{{ item[0].content }}</span>
        </div>
      </transition-group>
    </draggable> -->
    
    <transition name="el-fade-in-linear">
      
      <div  v-click-outside="fileClose" id = "fileDialog" class="fileDialog" :style = "{'top': fileTop}" v-if="fileVisible">
        <input maxlength="10"  v-model="fileName"/>
        <draggable class="wrapper-file swiper-no-swiping" :delay = 'isMobile ? 200 : 5'  :list="fileList" :group="groupA" @remove="fileOut" animation="300" :sort= "true">
          <transition-group class="itemAll">
            <div @contextmenu.stop ="appMeun(fileIndex,index)" @click="fileAppClick(item[0].url)"
                   v-for="(item, index) in fileList" :key="index" class="item" :style="{backgroundColor:item[0].value}">
              <img v-if="item.length === 1&&item[0].type == 0" class="appImg" :src="item[0].value =='' ?  'data:image/png;base64,'+item[0].valueBase64 : require('../assets/icon/' + item[0].value)" :style="{backgroundColor: item[0].bgc}" alt="">
              <span class="content" v-if="item[0].type == 1"  :style="{backgroundColor: item[0].value}">{{ item[0].content }}</span> <img v-if="item[0].type == 2" class="appImg" :src="item[0].value "    :style="{backgroundColor: item[0].bgc}"  alt="">
              
              <div class="item-name">{{ item[0].name }}</div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </transition>
    
  </div>
</template>

<script>
import draggable from "vuedraggable";
import appDialog from "@/components/appDialog";
import memo from "@/components/memo.vue"
import {list,bottomList,otherList} from "./list";
import Schedule from '@/components/schedule.vue';
import TodoList from '@/components/todoList.vue';
import Hot from '@/components/hot.vue';
import Weather from '@/components/weather.vue';
import vClickOutside from 'v-click-outside'
import Music from './music.vue';
import Chatgpt from './chatgpt.vue';
import Holiday from './holiday.vue';

export default {
  name: "HelloWorld",
  components: { draggable , appDialog,memo, Schedule, TodoList, Hot, Weather, Music, Chatgpt, Holiday,},
  props:['showPart','page','memoList', 'time', "isMobile"],
  data() {
    return {
      default_font_size: 16,
      fileVisible:false,
      memoDialogVisible: false,
      fileIndex:undefined,
      fileList:[],//文件夹图标
      otherList:[//顶部组件
      ],
      list: [], //app
      allList:[],
      list1:[],
      list2:[],
      addOrEditPage: 0,
      addOrEditIndex:0,
      listIndex:0,
      fileTop: '0px',
      bottomList: [], //底部app
      groupA:{
        name:'site',
        pull:true, //可以拖出
        put:true//可以拖入
      },
      fileName: '',
      groupB:{
        name:'site',
        pull:false,
        put:true
      },
      groupC:{
        name:'site',
        pull:false,
        put:false
      },
      dataDragon:[],
      
    };
  },
   watch:{
     fileName(){
        this.list[this.fileIndex].forEach((self,index) =>{
          this.list[this.fileIndex][index]['file'] = this.fileName
        });
      console.log(this.list)


     }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  created(){
    //if (!localStorage.getItem("list")) localStorage.setItem("list", JSON.stringify(list));
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList[this.page]
    
   
  },
  methods: {
    remove(){
      console.log('remove')
      this.list.forEach(item=>{
        if (item.length>1) {
          item.forEach((itemIn,i)=>{
            if (Array.isArray(itemIn)) item.splice(i,1,...itemIn)
          })
        }
      })
   
      console.log('合并文件夹',this.list)
      this.allList[this.page] = this.list
      localStorage.setItem("list", JSON.stringify(this.allList))
      
      this.$emit("updated")
      if(this.fileVisible) this.fileVisible = false
    },
    clone(){
      console.log('clone')
    },
    musicInfo(isMove){
        this.$emit('appViewInfo',isMove)
    },
    appClick(type,url){
      if(type == 0 || type == 1){
        window.open(url)
      }
    },
    fileAppClick(url){
      window.open(url)
    },
    appMeun(index,inFileIndex){
      console.log('右键点击', this.page, index, inFileIndex)
      this.$emit("rightClick", this.page, index, inFileIndex)
    },
    
    updatePageIcon(){
      console.log('更新当页',this.list,this.page);
      this.allList = JSON.parse(localStorage.getItem("list"))
      this.list = this.allList[this.page] 
    
    },
    reviewList(){
      this.allList = JSON.parse(localStorage.getItem("list"))
      this.allList[this.page] = this.list
      localStorage.setItem("list", JSON.stringify(this.allList))
      this.list = this.allList[this.page] 
    
    },
    jump(type,item,index,url) {
      this.fileVisible = false
      if(type == 3){
        if(url == 'weather'){
          this.$refs.weather[0].openWeather()
        }else{
          this.$emit('minicompomentClick',item[0].url)
        }
      }else if(item.length>1) {
        console.log('打开文件夹')
        this.fileIndex = index
        this.fileName = this.list[index][0]['file']
        this.fileList = item.map(e=>{
          return [e]
        })
        this.fileVisible = true
        this.fileTop = document.getElementById('center').scrollTop + 'px'
        
        this.$emit('appViewInfo',this.fileVisible)
        return
      }else{
        if(url == "add"){
          this.$emit('minicompomentClick',url)
        }else{
          window.open(url)
        }
      }
    },
    fileOut() {
      console.log(this.fileList);
        this.list[this.fileIndex] = this.fileList.map(e=>{
          return e[0]
        })
        this.allList[this.page] = this.list
        localStorage.setItem("list", JSON.stringify(this.allList))
        this.list = this.allList[this.page]
      
      this.fileVisible = false
      this.$emit("updated")
      this.$emit('appViewInfo',this.fileVisible)
    },
    fileClose() {
      console.log('文件夹关闭');
      if (this.fileVisible === true) this.fileVisible = false
      this.$emit('appViewInfo',this.fileVisible)
    },
    showMenu (event) {
      console.log(event);
        event.preventDefault()
        var x = event.clientX
        var y = event.clientY-125
        // Get the current location
      this.contextMenuData.axis = {
        x, y
      }
    },
    addMain(){
      //this.close()
      this.allList[this.page] = this.list
      localStorage.setItem("list", JSON.stringify(this.allList));
    },
    close(){
      // this.reviewList()
      if (!localStorage.getItem("list")) localStorage.setItem("list", JSON.stringify(list));
      this.allList = JSON.parse(localStorage.getItem("list"))
      
      this.list = this.allList[this.page]
      // if(this.page === 2) this.list = JSON.pars
      //this.dialogFormVisible =false
      //this.$emit("updated")
    },
    onMove(e) {
      console.log(e.relatedContext.element);
        if (e.relatedContext.element[0]?.comp == 1) return false;
        return true;
    },
    getDate(){
      var date = new Date();
        var year = date.getFullYear();    //  返回的是年份
        var month = date.getMonth() + 1;  //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate();       //  返回的是几号
        var arr = [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六",];
        var day = arr[date.getDay()];          //  周一返回的是1，周六是6，但是周日是0
        var hour = date.getHours();
        var minutes= date.getMinutes()
        return {
        year ,month ,dates ,day ,hour,minutes
      }
    },
    add(index,e){//合并文件夹
      console.log('add',index)
      this.list.forEach(item=>{
        if (item.length>1) {
          item.forEach((itemIn,i)=>{
            if (Array.isArray(itemIn)) item.splice(i,1,...itemIn)
          })
        }
      })
   
      console.log('合并文件夹',this.list)
      this.allList[this.page] = this.list
      localStorage.setItem("list", JSON.stringify(this.allList))
      
      this.$emit("updated")
      if(this.fileVisible) this.fileVisible = false
      
    },
    addOut(){
      console.log('addOut',this.list);
      this.list.forEach((item,i)=>{
        if (!Array.isArray(item)) this.list[i] = [item];
      })
    },
    datadragEnd(evt) {//图标志位置交换
      console.log('datadragEnd',this.list);
        this.allList[this.page]  = this.list
        localStorage.setItem("list", JSON.stringify(this.allList))
    
    },
    datadragEndSub(index, evt) {
      console.log('datadragEndSub',this.list);
    },
  },
};
</script>
<style scoped lang="less">
.fileDialog::-webkit-scrollbar{
    width: 0;
  }
.fileDialog{
  position: absolute;
  z-index: 99;
  top: 0;
  left: 10%;
  bottom: 0;
  right: 0;
  max-height: 75%;
  max-width: 80%;
  height: fit-content;
  overflow-y: auto;
  border-radius: 2rem;
  background-color: rgba(57, 54, 54, 0.447);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  padding: 15px;
  align-items: center;
  justify-content: center;
  input{
    font-size: 1.3rem;
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 1rem;
    border: none;
  }
  .wrapper-file{
    display: flex;
    justify-content: center;
    width: 100%;
    .itemAll {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    .appImg{
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
    }
    // width: 100%;
      >div{
        margin: 1rem;
        width: 4rem;
        height: 4rem;
        background-color: #00000000;
        color: #ffffff;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        text-align: center;
        .item-name{
          position: absolute;
          bottom: -21px;
          transform: translate(-50%, 0);
          left: 50%;
          z-index: 1000;
          color:#fff;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
        }
        .content{
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18px;
          font-weight:bold;
          transform: translate(-50%, -50%);
          width: 100%;
          overflow: hidden;
          background-color: rgb(9, 132, 254);
          height: 100%;
          border-radius: 1rem;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          display: flex;
        }
      }
    }
  }
}
.wrapper-top{
  display: flex;
  justify-content: center;
  width: 100%;
  .itemTop{
    width: 1500px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    >div{
      margin: 20px;
    }
    .item{
      width: 200px;
      height: 200px;
      color: #ffffff;
      border-radius: 30px;
      cursor: pointer;
    }
    .item1{
      width: 14rem;
      height: 9rem;
      color: #ffffff;
      border-radius: 30px;
      cursor: pointer;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .item {
    background-color: #00000000;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    .item-name{
      position: relative;
      z-index: 1;
      color:#fff;
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
    }
    .content{
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 18px;
      font-weight:bold;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 1rem;
      white-space: nowrap;
    }
  }
  .itemAll{
    width: 1500px;
    display: grid;
    justify-content: start;
    flex-wrap: wrap;
  }
}
.wrapper-in{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  .itemAll-in {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
      >div{
        margin: 3%;
        height: 27%;
        width: 27%;
        .item-in{
          width: 100%;
          height: 100%;
          color: #4e5250;
          border-radius: 4px;
          position: relative;
          .content {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 50%;
              color: white;
              font-size: 12px;
              font-weight: bold;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              overflow: hidden;
              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 1rem;
              white-space: nowrap;
          }
          .appSmallImg{
            height: 100%;
            width: 100%;
            border-radius: 0.5rem;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .itemAll{
      width: 1500px;
      display: grid;
      justify-content: center;
      flex-wrap: wrap;
    }
}
.timeBox{
  background-color: #282525;
  display: flex;
  align-items: center;
  >div{
    flex: 1;
    height: 80px;
    .time1{
      font-size: 3.125rem;
    }
    .time2{
      font-size: 0.9375rem;
    }
  }
}

.wrapper-bottom{
  width: 100%;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  background-color: rgba(15, 15, 15, 0.151);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  .item {
    margin: 10px;
    width: 50px;
    height: 50px;
    background-color: #4e5250;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    .content{
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 12px;
      font-weight:bold;
      transform: translate(-50%, -50%);
    }
  }
  .itemAll{
      width: 1500px;
      display: grid;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px 0;
    }
}
.Box{
  background-size:100% 100%;
  background-repeat: repeat;
}
.reBox{
  background-image: url('../assets/热搜.jpg');
  background-size:100% 100%;
  background-repeat: repeat;
  background-color: #fff;
}
::v-deep.vue-contextmenu-listWrapper{
  padding: 0;
  background-color: #23222286!important;
  color: #fff;
  box-shadow:0 0 0 0!important;
  .context-menu-list{
    background-color: #ffffff00!important;
  }
}

::v-deep.ulGrid{
    position: relative;
    display: grid;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    grid-template-columns: repeat(auto-fill,4rem);
    grid-template-rows: repeat(auto-fill,4rem);
    grid-auto-flow: dense;
    box-sizing: border-box;
    justify-content: center;
    list-style: none;
    grid-gap: 1.5rem;
}

.icon-2-1{
	grid-row:span 2;
	grid-column:span 1;
  border-radius:1rem;
  width: 4rem;
  height: 9.5rem;
  .div-img{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    .appImg {
      width: 100%;
      height: fit-content;
      border-radius: 1rem;
    }
  }
}
.icon-2-2{
	grid-row:span 2;
	grid-column:span 2;
  width: 9.5rem;
  height: 9.5rem;
  border-radius:1rem;
  .div-img{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    .appImg {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
  }
}
.icon-1-2{
	grid-row:span 1;
	grid-column:span 2;
  border-radius:1rem;
  width: 9.5rem;
  height: 4rem;
  .div-img{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    .appImg {
      height: 100%;
      width: fit-content;
      border-radius: 1rem;
    }
  }
}

.icon-2-4{
  grid-row: span 2;
  grid-column: span 4;
  width: 20.5rem;
  height: 9.5rem;
  border-radius:1rem;
}
.icon-4-2{
  grid-row: span 4;
  grid-column: span 2;
  width: 9.5rem;
  height: 20.5rem;
  border-radius:1rem;
}
.icon-4-4{
  grid-row: span 4;
  grid-column: span 4;
  width: 20.5rem;
  height: 20.5rem;
  border-radius:1rem;
  .div-img{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    .appImg {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
  }
}
.icon-1-1{
  grid-row: span 1;
  grid-column: span 1;
  width: 4rem;
  height: 4rem;
  border-radius:1rem;
  .div-img{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    .appImg {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
  }
  
}
.hidden{
  overflow: hidden;
}
</style>