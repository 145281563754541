<template>
  <div >
    <el-dialog 
    
    :width="isMobile ? '95%' : '70%'"
    class="gptDialog" :title="'Chat GPT'" :modal="false" :append-to-body="true" :visible.sync="dialogFormVisible" :before-close="close"   top = "10vh">
      <!-- <iframe  class="gpt"  src = "https://chat12.aichatos.xyz/"></iframe> -->
      <iframe  class="gpt"  src = "https://chat.jinshutuan.com"></iframe>
      
    </el-dialog>
  </div>
</template>

<script>
import {list,compomentList,iconPreList} from "./list";

export default {
  name:'appDialog',
  props:["dialogFormVisible","activeIndex", "addOrEditIndex","isMobile"],
  data() {
    return {
     
    }
  },
  created() {
    this.allList = JSON.parse(localStorage.getItem("list"))
    this.list = this.allList.filter(e=>e.length===1)
    this.compomentList = compomentList
    this.iconPreList = iconPreList
    console.log(this.compomentList)
    if(this.isEdit){
      console.log(this.form);
    }
  },
  methods:{
   close(){
      console.log('关闭窗口')
      this.$emit('updated')
    },
  }
}
</script>

<style lang="less">

.gptDialog{
  text-align: start;
  background-color: rgba(0,0,0,0.5);
  .el-dialog{
    width: 70%;
    height: 80%;
    overflow: hidden;
  }
  .el-dialog__body{
    display: flex;
    padding: 0;
    height: 87%;
    .gpt{
      width: 100%;
    }
    
  }
  .el-form-item__label{
    text-align: left;
  }
  
.left{
  flex: 1 0 16%;
  padding-left: 1rem;
  padding-top: 1rem;
  .left-li{
    display: flex;
    font-size: 1.2rem;
    padding-bottom: 1rem;
    svg{
      width: 1.2rem;
    }
    span{
      padding-left: 0.5rem;
      font-weight: 1000;
    }
  }
}
.right::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
      background-color: darkgray;
      border-radius: 0.5rem;
  }
.right{
  flex: 1 0 80%;
  overflow: auto;
  .el-form{
    width: 80%;
  }
  .group{
    display: grid;
    grid-template-columns: repeat(auto-fill,4rem);
    grid-template-rows: repeat(auto-fill,4rem);
    grid-auto-flow: dense;
    grid-gap: 1.5rem;
    .icon-1-1{
      grid-row: span 1;
      grid-column: span 1;
      width: 4rem;
      height: 4rem;
      border-radius:1rem;
      .appImg{
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
    }
    .item-name{
      position: relative;
      z-index: 1;
      color:black;
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

}
</style>