<template>
  <div class="daibanhome">
      <div class="left">
        <ul>
          <li>所有待办</li>
        </ul>
      </div>
      <div class="right">
        <div class="top">
            <ul>
              <li v-for="(item,index) in datalistDo" :key="index">
                <div class="list1">
                  <el-checkbox v-model="item.checked" @change="changeDoStatus(index,'do')" :label="item.id + '-' + index" :class="['checkbox' + item.status]"    >{{item.title}}</el-checkbox>
                </div>
                <div class="list2">
                  <el-popover
                  placement="bottom"
                  width="260"
                  trigger="click">
                    <div class="selectBox">
                      <span class="tip">优先级</span>
                      <span class="select select1" @click="changeColor(index,1)">
                        <i class="el-icon-check" v-show="item.status==1"></i>
                      </span>
                      <span class="select select2" @click="changeColor(index,2)">
                        <i class="el-icon-check" v-show="item.status==2"></i>
                      </span>
                      <span class="select select3" @click="changeColor(index,3)">
                        <i class="el-icon-check" v-show="item.status==3"></i>
                      </span>
                      <span class="select select4" @click="changeColor(index,4)">
                        <i class="el-icon-check" v-show="item.status==4"></i>
                      </span>
                    </div>
                    <i class="el-icon-more button" slot="reference"></i>
                  </el-popover>
                    <i class="el-icon-delete button" @click="del(index,'do')"></i>
                </div>
              </li>
              </ul>
              <ul>
                <br>
              <li v-for="(item,index) in datalistNotDo" :key="index">
                <div class="list1">
                  <el-checkbox v-model="item.checked" @change="changeDoStatus(index,'notdo')" :label="item.id + '-' + index"  checked :class="['check','checkbox' + item.status]"  >{{item.title}}</el-checkbox>
                </div>
                <div class="list2">
                  <el-popover
                  placement="bottom"
                  width="260"
                  style = "visibility:hidden;"
                  trigger="click">
                    <div class="selectBox">
                      <span class="tip">优先级</span>
                      <span class="select select1" @click="item.status=0">
                        <i class="el-icon-check" v-show="item.status==0"></i>
                      </span>
                      <span class="select select2" @click="item.status=1">
                        <i class="el-icon-check" v-show="item.status==1"></i>
                      </span>
                      <span class="select select3" @click="item.status=2">
                        <i class="el-icon-check" v-show="item.status==2"></i>
                      </span>
                      <span class="select select4" @click="item.status=3">
                        <i class="el-icon-check" v-show="item.status==3"></i>
                      </span>
                    </div>
                    <i class="el-icon-more button" slot="reference"></i>
                  </el-popover>
                    <i class="el-icon-delete button" @click="del(index,'notdo')"></i>
                </div>
              </li>
              
            </ul>
        </div>
        <div class="bottom">
          <i class="el-icon-plus" @click="add()"></i>
          <input type="text" placeholder="添加任务" v-model="value" @keyup.enter="add()">
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'home',
  data(){
    return {
      datalistNotDo: [],
      datalistDo:[
      ],
      value:"",
      todoList: {},
      checked: true,
      notchecked: false,
    }
  },
  created(){
      if (localStorage.getItem("todoList")){
        this.todoList = JSON.parse(localStorage.getItem("todoList"))
        this.datalistDo = this.todoList['datalistDo']
        this.datalistNotDo = this.todoList['datalistNotDo']
      } 

  },
  computed:{
    
  },
  methods: {
    changeColor(index,color){
      this.datalistDo[index].status = color
      this.updateData()
    },
    updateData(){
        this.todoList['datalistDo'] = this.datalistDo 
        this.todoList['datalistNotDo'] = this.datalistNotDo
        localStorage.setItem("todoList", JSON.stringify(this.todoList));
    },
    changeDoStatus(index,e){
      if(e == 'do'){
        var s = this.datalistDo.splice(index,1)[0]
        s['checked'] = true
        this.datalistNotDo.push(s)
      }else{
        var s = this.datalistNotDo.splice(index,1)[0]
        s['checked'] = false
        this.datalistDo.push(s)
      }
      console.log('do',this.datalistDo)
      console.log('not do',this.datalistNotDo)
      this.updateData()

    },
    del(index,e){
      if(e == 'do'){
        this.datalistDo.splice(index,1)
      }else{
        this.datalistNotDo.splice(index,1)
      }
      this.updateData()
    },
    add(){
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var title = year + seperator1 + month + seperator1 + strDate;

     
      this.datalistDo.push({
          id: this.datalistDo.length + 1,
          title:this.value,
          time:title,
          status:0,
          checked: false
      })
      this.value = ""
      this.updateData()
    }
  }
}
</script>
<style lang="less">
    .el-popover{
      .selectBox{
        display: flex;
        align-items: center;
      }
      .tip{
        font-size: 14px;
      }
      .select{
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        margin-left: 10px;
        cursor: pointer;
      }
      .select i{
        color: #fff;
        font-size: 18px;
      }
      .select1{
        background-color: #E03131;
      }
      .select2{
        background-color: #FFB000;
      }
      .select3{
        background-color: #4772FA;
      }
      .select4{
        background-color: #939393;
      }
    }
  .daibanhome{
    
    *{
      margin:0;
      padding: 0;
      box-sizing: border-box;
      list-style-type: none;
    }
    height: 100%;
    display: flex;
    .left{
      flex: 0 0 20%;
      // padding: 20px;
      max-width: 200px;
      text-align: center;
      padding-top: 30px;
      li{
        padding-bottom: 8px;
        font-size: 20px;
        font-weight: 1000;
      }
    }
    .right{
      flex: 1 0 80%;
      background-color: #ededed;
      position: relative;
      padding-top: 2rem;
      overflow: hidden;
      .bottom{
        // position: fixed;
        // bottom: 20px;
        // left: 55%;
        // transform: translateX(-50%);
        // width: 80%;
        position: absolute;
        bottom: 20px;
        transform: translateX(-50%);
        left: 50% ;
        width: 96%;
        margin:0 auto;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.1);
        i{
          color: #909399;
          font-size: 19px;
          cursor: pointer;
        }
        input{
          width: 90%;
          height: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          padding-left: 10px;
          font-size: 16px;
        }
      }

      .top{
        overflow-y: auto;
        height: 500px;
        ::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.5rem;
            background-color: darkgray;
            border-radius: 0.5rem;
        }
        li{
          display: flex;
          justify-content: space-between;
          align-items: top;
          width: 96%;
          margin:10px auto;
          background-color: #fff;
          padding: 10px 20px;
          border-radius: 10px;
          box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.1);
          .list2{
            flex:0 0 10%;
            max-width: 80px;
            display: flex;
            justify-content: flex-end;
            .button{
              font-size: 16px;
              color: #909399;
              cursor: pointer;
            }
            .button:nth-child(1){
              margin-right: 8px;
            }
          }
          .list1{
            flex:1 0 90%;
            display: flex;
            .check .el-checkbox__label{
              text-decoration:line-through;
            }
            .el-checkbox{
              display: flex;
            }
            .el-checkbox__label{
              font-size: 16px;
              color:#909399;
              white-space: normal;
              text-align: start;
              padding-left: 1rem;
            }
            .el-checkbox__inner{
              width: 24px;
              height: 24px;
              border-width: 3px;
              border-radius: 0.4rem;
            }
            .el-checkbox__inner:after{
              content:"";
              width: 6px;
              height: 12px;
              left: 34%;
            }
            .checkbox1{
              .el-checkbox__inner{
                border-color: #E03131;
              }
              .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: #E03131;
              }
            }
            .checkbox2{
              .el-checkbox__inner{
                border-color: #FFB000;
              }
              .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: #FFB000;
              }
            }
            .checkbox3{
              .el-checkbox__inner{
                border-color: #4772FA;
              }
              .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: #4772FA;
              }
            }
            .checkbox4{
              .el-checkbox__inner{
                border-color: #939393;
              }
              .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: #939393;
              }
            }
            i{
              font-size: 16px;
              color: #909399;
            }
            .time{
              padding-left: 32px;
              margin-top: 4px;                
              font-size: 12px;
              color: #909399;
              i{
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
</style>
