<template>
  <div class="weather weather-sunny">
      <div class="app-item app-item-icon" title="i天气">
	<div class="d-watch-resize" data-v-c8820537="" data-v-774b69dc="">
		<div class="icon-size-2x4 weather-sunny_n weather-icon" data-v-774b69dc="">
			<!---->
			<!---->
			<!---->
			<!---->
			<div class="weather-icon-medius d-flex-between wfull hfull" data-v-774b69dc="">
				<div class="d-flex-between wfull" data-v-774b69dc="">
					<div data-v-774b69dc="" style="flex-direction: column; display: flex;line-height: 0.88;justify-content: space-between;">
						<span data-v-774b69dc="" style="font-size: 12px;">{{weatherNow.city}} <i class="d-icon" data-v-774b69dc="" style="margin-left: 2px; font-size: 12px; vertical-align: -2px;">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-774b69dc="">
									<path fill="currentColor" d="m249.6 417.088 319.744 43.072 39.168 310.272L845.12 178.88 249.6 417.088zm-129.024 47.168a32 32 0 0 1-7.68-61.44l777.792-311.04a32 32 0 0 1 41.6 41.6l-310.336 775.68a32 32 0 0 1-61.44-7.808L512 516.992l-391.424-52.736z"></path>
								</svg>
							</i>
						</span>
						<p class="mt5 b" data-v-774b69dc="" style="font-size: 2rem;">{{weatherNow.temp}}°</p>
					</div>
					<div class="ar" data-v-774b69dc="" style="line-height: 0.2; font-size: 0.77rem;">
						<p data-v-774b69dc="">{{weatherNow.text}}
							<img title="晴"  class="mr5" :src="require('../assets/weather/' + weatherNow.icon + '-fill.svg')" data-v-774b69dc="" style="width: 22px; height: 22px; vertical-align: -4px;">
						</p>
						<p data-v-774b69dc="" style="margin-top: 0.3em;"> 最低 {{weatherList[0].tempMin}}° 最高 {{weatherList[0].tempMax }}° </p>
					</div>
				</div>
				<ul class="d-flex-between wfull" data-v-774b69dc="" style="font-size: 0.65rem; line-height: 0.28; margin-top: 0.2em;">
					<!---->
					<li data-v-774b69dc="" v-for="(item, index) in weatherList.slice(1)" :key="index">
						<p data-v-774b69dc="">{{index == 0? '明天' : weekList[(weekDay + 1 + index)%7]}}</p>
						<p class="d-flex-center" data-v-774b69dc="">
							<img class="mr5" :src="require('../assets/weather/' +  item.iconDay + '-fill.svg')" data-v-774b69dc="" style="width: 16px; height: 16px;">
						</p>
						<p data-v-774b69dc="">{{item.tempMin}} / {{item.tempMax}} </p>
					</li>
					
				</ul>
			</div>
		</div>
	</div>
</div>
    </div>
</template>

<script>
import axios from 'axios';
import {weatherList,weatherNow} from "./list";
export default {
  name: 'weather',
  data(){
    return{
		weatherList:[],
        weekList: [ "周日","周一","周二","周三","周四","周五","周六",],
		weekDay: 0,
		weatherNow: {},
	}
  },
  created() {
	if (!localStorage.getItem("weatherList")) localStorage.setItem("weatherList", JSON.stringify(weatherList));
    this.weatherList = JSON.parse(localStorage.getItem("weatherList"))
	if (!localStorage.getItem("weatherNow")) localStorage.setItem("weatherNow", JSON.stringify(weatherNow));
    this.weatherNow = JSON.parse(localStorage.getItem("weatherNow"))
	this.weekDay = new Date().getDay()

	console.log('是否刷新当天数据',this.getDayStr(new Date()) != this.weatherList[0].fxDate )
	if(this.getDayStr(new Date()) != this.weatherList[0].fxDate ){
		axios.get('https://ipage.cloud/ipage/weather').then(
		  res => {
        console.log('网络请求成功',res)
        if(res.data.code == 200){
          this.weatherList = res.data.daily
		  localStorage.setItem("weatherList", JSON.stringify(this.weatherList));
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )
	}
	console.log('是否刷新实时数据',(new Date() - new Date(this.weatherNow.obsTime)) / 1000 /60 > 60 )
	if((new Date() - new Date(this.weatherNow.obsTime)) / 1000 /60 > 60){
		axios.get('https://ipage.cloud/ipage/weathernow').then(
		  res => {
        console.log('网络请求成功',res)
        if(res.status == 200){
          this.weatherNow = JSON.parse(res.data.weathernow).now
        if(res.data.district){
          this.weatherNow.city = res.data.district
        }else{
          this.weatherNow.city = res.data.city
        }
		  this.weatherNow.fxLink = JSON.parse(res.data.weathernow).fxLink
		  console.log('定位',this.city,this.weatherNow)

		  localStorage.setItem("weatherNow", JSON.stringify(this.weatherNow));
        }
			  
		  },
		  error => {
			  console.log('网络请求失败',error)
		  }
	  )
	}
	  
	  this.getLongitudeLatitude()
  },
  methods:{
	openWeather(){
		if(!this.weatherNow.fxLink == ''){
			window.open(this.weatherNow.fxLink)
		}
	},
    getDayStr(nowDate){
		var str = nowDate.getFullYear()+ '-'
	 	str = str + ((nowDate.getMonth() + 1) + '-').padStart(3,'0')
		str = str + (nowDate.getDate() + '').padStart(2,'0')
      	return  str
    },
	getLongitudeLatitude() {
      //如果该对象存在，那么地理位置服务可用。
      if ('geolocation' in navigator) {
	  console.log('获取您的位置')
        /* 地理位置服务可用 */
        var options = {
          enableHighAccuracy: false, //布尔值，表示系统是否使用最高精度来表示结果，注意，这会导致较慢的响应时间或者增加电量消耗（比如对于支持gps的移动设备来说）。如果值为false ，设备会通过更快响应以及/或者使用更少的电量等方法来尽可能的节约资源。默认值fasle
          timeout: 5000, //它表明的是设备必须在多长时间（单位毫秒）内返回一个位置。默认直到获取到位置才会返回值。
          maximumAge: 0 //表明可以返回多长时间（即最长年龄，单位毫秒）内的可获取的缓存位置。如果设置为 0, 说明设备不能使用一个缓存位置，而且必须去获取一个真实的当前位置。默认0
        }
        function success(position) {
          //position.coords (只读) 返回一个定义了当前位置的Coordinates对象。
          //position.timestamp (只读) 返回一个时间戳DOMTimeStamp， 这个时间戳表示获取到的位置的时间。
          var lat = position.coords.latitude //当前位置的纬度
          var lng = position.coords.longitude //当前位置精度
          console.log(lat,lng)
        }
        function error(err) {
          var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时']
          console.log(errorType[err.code - 1])
        }
 
        navigator.geolocation.getCurrentPosition(success, error,options)
      } else {
        /* 地理位置服务不可用 */
        console.log('无法获取您的位置，请检查定位是否开启或刷新重试')
      }
    },

 }
}
</script>

<style>
.mr5 {
    filter: drop-shadow(white 1000px 0);
    transform: translateX(-1000px);
}
.weather{
    width: 100%;
    height: 100%;
    background-color: #154280;
    
    overflow: hidden;
    border-radius: 1rem;
}
.weather-snow{
	background-image: linear-gradient(45deg,#000012 20%,#29334e);
}
.weather-sunny{
	background-image: linear-gradient(35deg,#154280 30%,#335693,#a8b3d2)
}
.app-item-icon {
    position: relative;
    overflow: hidden;
    border-radius: var(--icon-radius);
    box-shadow: 0 0 5px #0000001a;
    transition: .2s;
    cursor: pointer;
    padding: 1rem;
}
.d-watch-resize[data-v-c8820537] {
    position: relative;
    width: 100%;
    height: 100%;
}
.weather-icon[data-v-774b69dc] {
    position: relative;
    color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.weather-icon-medius[data-v-774b69dc] {
    flex-direction: column;
}
.d-flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wfull {
    width: 100%;
}
.hfull {
    height: 100%;
}
.ar {
    text-align: right;
}
.d-flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.d-icon svg {
    height: 1em;
    width: 1em;
}
.mt5 {
    margin-top: 1rem;
    margin-block-end: 0.5rem;
}
.b {
    font-weight: 700;
}
ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
    list-style: none;
    padding-right: 1rem;
    padding-inline-start: 0px;
    padding-inline: 0px;
}
</style>